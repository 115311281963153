.about-self-header{
  padding: 22px 30px;
  @media(min-width: 768px) and (max-width: 991px){
    background: #000000;
  }
  @media (max-width: 767px){
    background: #000000;
  }
  .about-self-username{
    font-weight: normal;
    font-size: 19px;
    line-height: 36px;
    color: #5B5B5B;
    padding: 0 10px;
    @media (min-width: 768px) and (max-width: 991px){
      color: #fff;
    }
    @media (max-width: 767px){
      color: #fff;
    }
  }
}



@media screen and (max-width: 991px) {
  .menu-btn {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  }
}