.upload-agreements-wrapper {
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
    background: #fff;
  }
  .header-left {
    margin-bottom: 25px;
    @media (max-width: 991px) {
      margin: 25px 15px;
      padding: 0 30px;
    }
    @media (max-width: 375px) {
      flex-direction: column;
      row-gap: 15px;
      align-items: flex-start !important;
    }
    .back-button {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #000000;
      span {
        margin-right: 5px;
      }
    }
    .agreements-templates {
      .agreements-templates-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        margin-right: 10px;
        @media (max-width: 425px) {
          font-size: 15px;
        }
      }
      .agreements-templates-sow {
        font-weight: 500;
        font-size: 17px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        border: 2px solid #e5c243;
        box-sizing: border-box;
        border-radius: 34px;
        padding: 5px 20px;
        margin-right: 10px;
        @media (max-width: 425px) {
          font-size: 15px;
        }
      }
      .agreements-templates-msa {
        font-weight: 500;
        font-size: 17px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        border: 2px solid #e5c243;
        box-sizing: border-box;
        border-radius: 34px;
        padding: 5px 20px;
        @media (max-width: 425px) {
          font-size: 15px;
        }
      }
    }
  }
  .upload-agreements-main-wrapper {
    @media (min-width: 768px) and (max-width: 991px) {
      padding: 0 30px;
    }
    @media (max-width: 767px) {
      padding: 0 15px;
    }
    .upload-agreements-title-wrapper {
      background: #000;
      color: #fff;
      padding: 22px 33px;
      border-radius: 13px 13px 0 0;
      @media (max-width: 767px) {
        padding: 22px 20px;
        margin: 0 15px;
      }
      .upload-agreements-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-transform: capitalize;
        color: #fff;
        margin-bottom: 0;
        @media (max-width: 767px) {
          text-align: center;
          //margin-bottom: 25px;
        }
        img {
          margin-right: 10px;
        }
      }
    }
    .upload-agreements-card-main {
      background: #ffffff;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 0 0 13px 13px;
      padding: 50px 0 30px;
      margin-bottom: 50px;
      @media (max-width: 767px) {
        padding: 10px 0 0;
        margin: 0 15px;
      }
      .upload-agreements-card {
        padding: 0 50px;
        @media (min-width: 768px) and (max-width: 991px) {
          padding: 0 40px;
        }
        @media (max-width: 767px) {
          padding: 20px 20px 0;
        }
        .upload-agreements-form {
          .upload-agreements-user-main {
            margin-top: 50px;
            .upload-agreements {
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              color: #000000;
              margin-bottom: 15px;
            }
            .upload-agreements-user-wrapper {
              span {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .file-upload-wrapper {
                background: #ffffff;
                border: 2px dashed #000000;
                box-sizing: border-box;
                border-radius: 33px;
                padding: 45px 0;
                @media (max-width: 767px) {
                  padding: 45px 20px;
                }
                .file-upload-main {
                  .description-sec {
                    font-weight: 500;
                    font-size: 19px;
                    line-height: 29px;
                    text-align: center;
                    color: #000000;
                    margin-top: 30px;
                    margin-bottom: 25px;
                    width: 300px;
                    @media (max-width:767px){
                      width: 100%;
                    }
                  }
                  .btn-file {
                    background: #e5c243;
                    border-radius: 36px;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    color: #000000;
                    padding: 10px 15px;
                    width: 195px;
                  }
                }
              }
            }
          }
        }
      }
      .agreements-buttons {
        padding: 18px 54px;
        background: #ffffff;
        border-radius: 13px;
        margin: 40px 0 20px;
        @media (max-width: 767px) {
          padding: 20px 0;
          flex-direction: column-reverse;
          row-gap: 19px;
        }
        .button-group {
          width: 100%;
          @media (max-width: 767px) {
            flex-direction: row;
            column-gap: 10px;
          }
          @media (max-width: 375px) {
            flex-direction: column;
            row-gap: 19px;
          }
          .button-cancel {
            border: 2px solid #e5c243;
            box-sizing: border-box;
            border-radius: 53px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            text-transform: capitalize;
            color: #000000;
            padding: 11px 28px;
            background: transparent;
            @media (min-width: 768px) and (max-width: 991px) {
              padding: 10px 25px;
              font-size: 18px;
            }
            @media (max-width: 767px) {
              padding: 10px 45px;
            }
            @media (max-width: 375px) {
              width: 100%;
            }
          }
          .button-save {
            background: #e5c243;
            border-radius: 53px;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            text-transform: capitalize;
            color: #000000;
            padding: 13px 24px;
            margin-right: 15px;
            border: none;
            img {
              margin-right: 10px;
            }
            @media (min-width: 768px) and (max-width: 991px) {
              padding: 12px 20px;
              font-size: 18px;
            }
            @media (max-width: 767px) {
              padding: 10px 30px;
              margin-right: 0;
            }
            @media (max-width: 375px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
