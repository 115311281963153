.client-invoice-main {
  @media (max-width: 991px) {
    padding: 0;
  }
  .client-invoice-desktop-wrap {
    display: block;
    .client-invoice-spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .ant-spin-dot-item {
        background-color: #e5c243;
      }
    }
    .total-credits {
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #162642;
      @media (min-width: 768px) and (max-width: 991px) {
        margin-bottom: 20px;
      }
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
    .main-client-wrapper {
      .table-header {
        background: #000000;
        border-radius: 14px;
        height: 53px;
        color: #ffffff;
        margin: 40px 0;
        padding: 0 22px;
        @media (min-width: 991px) and (max-width: 1199px) {
          padding: 0 12px;
        }
        .th-data {
          font-size: 15px;
          line-height: 24px;
          cursor: pointer;
          color: #fff;
          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 14px;
          }

          .img-icon {
            height: 8px;
            margin-left: 8px;
          }
          &:first-child {
            display: flex;
            justify-content: flex-start;
          }
          flex: 1;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
    .table-body {
      background: #ffffff;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 13px;
      height: 60px;
      margin-bottom: 30px;
      cursor: pointer;
      padding: 0 22px;
      @media (min-width: 992px) and (max-width: 1199px) {
        padding: 0 12px;
      }
      @media (max-width: 991px) {
        height: auto;
        flex-direction: column;
        padding: 22px;
      }

      .table-data {
        display: flex;
        align-items: center;
        flex: 1;

        width: 100%;
        @media (max-width: 991px) {
          justify-content: center;
        }
        .invoice-card {
          @media (max-width: 991px) {
            width: 300px;
            justify-content: space-between;
            margin-bottom: 20px;
          }
          @media (max-width: 320px) {
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .table-data-label {
            @media (max-width: 991px) {
              width: 120px;
            }
            @media (max-width: 320px) {
              width: unset;
            }
          }
          .date {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
          }
          .complete-status {
            display: flex;
            align-items: center;
            column-gap: 5px;
            .status-text {
              font-weight: 500;
              font-size: 15px;
              line-height: 24px;
              color: #53a100;
            }
          }
          .incomplete-status {
            display: flex;
            align-items: center;
            column-gap: 5px;
            .status-text {
              font-weight: 500;
              font-size: 15px;
              line-height: 24px;
              color: #e5c243;
            }
          }
          .amount {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
          }
          .incomplete {
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            color: #e5c243;
            .incomplete-img {
              margin-right: 8px;
            }
          }
        }
        .reminder-btn {
          background: #e5c243;
          border-radius: 34px;
          font-weight: 500;
          font-size: 15px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          padding: 5px 10px;
          display: flex;
          align-items: center;
          margin-left: 10px;
          &:hover,
          &:active {
            border: 0;
          }
        }
        .username {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
          @media (max-width: 991px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;
          }
          .invoice-img {
            width: 48px;
            margin-right: 15px;
            @media (min-width: 992px) and (max-width: 1199px) {
              width: 33px;
              margin-right: 5px;
            }
            @media (max-width: 991px) {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  .client-invoice-pagination {
    margin: 50px 0 80px;
    .ant-pagination {
      .ant-pagination-item {
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        font-size: 15px;
        color: #727272;
      }
      .ant-pagination-next {
        .ant-pagination-item-link {
          background: #ffffff;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          font-size: 15px;
          color: #727272;
        }
      }
      .ant-pagination-prev {
        .ant-pagination-item-link {
          background: #ffffff;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          font-size: 15px;
          color: #727272;
        }
      }
      .ant-pagination-item-active {
        background: #e5c243;
        color: #000;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        border: none;
        font-size: 15px;
      }
    }
  }
}