.profile-setting-dropdown {
  .profile-setting-wrapper{
    position: relative;
    .profile-user-name{
      font-weight: normal;
      font-size: 19px;
      line-height: 36px;
      color: #5B5B5B;
      padding: 0 5px;
      @media(max-width: 991px){
        color: #ffffff;
      }
    }
    .dropdown {
      button {
        background: transparent;
        border: none;
        &:focus {
          outline: none;
          box-shadow: none;
        }
        &:active {
          outline: none;
          background: transparent !important;
          border: none;
          box-shadow: none !important;
        }
      }
      .dropdown-menu {

        left: 55px !important;
        width: 270px;
        transform: translate3d(-175px, 52px, 0px) !important;
        padding: 10px 0;
        @media(max-width: 991px){
          right: -125px !important;
          top: 30px !important;
        }
        .dropdown-item {
          line-height: 38px;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 17px;
          color: #162642;
          padding: 3px 18px;
          img {
            width: 20px;
            margin-right: 10px;
          }
          &:hover{
            background: #E5C243;
          }
        }
        .dropdown-item.active{
          background: #E5C243;
        }
        .investor-text{
          line-height: 38px;
          display: flex;
          align-items: center;
          font-weight: normal;
          font-size: 14px;
          color: #3E6295;
          padding: 2px 10px;
          background: #E8ECF3;
          border-radius: 4px;
        }
      }
    }
  }

}