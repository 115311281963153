.clients-details-wrapper{
  width: 100%;
  @media(max-width: 768px){
    padding: 0 30px;
  }
  .header-left{
    margin-bottom: 25px;
    @media(max-width: 991px){
      margin: 25px 15px;
    }
    .back-button{
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #000000;
      span{
        margin-right: 5px;
      }
    }
  }
  .client-management-profile{
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 13px;

    .client-email-edit{
      padding: 15px 20px 0 0;
      cursor: pointer;
    }
    .client-management-wrapper{
      column-gap: 65px;
      padding: 15px 50px 35px;
      @media(min-width: 992px) and (max-width: 1199px){
        padding: 10px 25px 25px;
      }
      
      @media(max-width: 768px){
        flex-direction: column;
        row-gap: 25px;
        padding: 35px 35px;
      }
      @media(max-width: 320px){
        padding: 35px 15px;
      }
      .client-profile{
        .client-profile-img{
          background: #FFFFFF;
          box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.26);
          border-radius: 50%;
        }
        .user-name{
          font-size: 17px;
          line-height: 41px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
        }
        .active-btn{
          background: rgba(107, 209, 0, 0.34);
          border-radius: 34px;
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          text-transform: capitalize;
          color: #53A200;
          padding: 3px 12px;
          border: unset;
        }
      }
      .client-email{
        .email{
          padding-left: 20px;
          @media(max-width: 425px){
            padding-left: 13px;
          }
          .email-label{
            font-weight: normal;
            font-size: 13px;
            line-height: 26px;
            text-transform: capitalize;
            color: #000000;
          }
          .email-text{
            font-weight: 500;
            font-size: 19px;
            line-height: 26px;
            text-transform: capitalize;
            color: #000000;
            word-break: break-all;
            @media(max-width: 425px){
              font-size: 16px;
            }
          }
        }
      }
      .client-time{
        .time{
          padding-left: 20px;
          @media(max-width: 425px){
            padding-left: 13px;
          }
          .time-label{
            font-weight: normal;
            font-size: 13px;
            line-height: 26px;
            text-transform: capitalize;
            color: #000000;
          }
          .time-text{
            font-weight: 500;
            font-size: 19px;
            line-height: 26px;
            text-transform: capitalize;
            color: #000000;
            word-break: break-all;
            @media(max-width: 425px){
              font-size: 16px;
            }
          }
        }
      }
    }

  }
  .client-details-wrapper{
    .client-email{
      background: #FFFFFF;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      border-radius: 13px;
      padding: 15px 0 40px;

      .client-email-detail{
        padding: 15px 60px 0;
        column-gap: 113px;
        @media(min-width: 992px) and (max-width: 1199px){
          padding: 15px 30px 0;
        }
        @media(max-width: 991px){
          padding: 15px 20px 0;
        }
        @media(max-width: 767px){
          padding: 50px;
          flex-direction: column;
          row-gap: 50px;
        }

      }
    }
    .client-detail-main{
      background: #FFFFFF;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      border-radius: 13px;
      padding: 48px 60px;
      margin-top: 25px;
      margin-bottom: 25px;
      @media(min-width: 992px) and (max-width: 1199px){
        padding: 30px 25px;
      }
      @media(max-width: 991px){
        padding: 35px;
      }
      .client-detail{
        padding-bottom: 40px;
        border-bottom: 1px solid #E5C243;
        @media(max-width: 767px){
          flex-direction: column;
        }
        .details-client{
          margin-left: 20px;
          .client-edit{
            @media(max-width: 767px) {
              margin-top: 15px;
            }
          }
          @media(max-width: 767px){
            margin-left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
          }
          .client-name{
            font-weight: 500;
            font-size: 28px;
            line-height: 41px;
            text-transform: capitalize;
            color: #000000;
            margin-right: 8px;
            @media(max-width: 767px){
              font-size: 20px;

            }
          }
          .client-text{
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #000000;
            @media(max-width: 767px) {
              margin-top: 15px;
            }
          }
        }
      }
      .client-desc{
        margin-top: 50px;
        .desc-main{
          @media (max-width: 767px){
            row-gap: 25px;
          }
        }
        .description{
          width: 200px;
          @media(min-width: 768px) and (max-width: 991px){
            width: 160px;
          }
          .client-desc-label{
            font-weight: normal;
            font-size: 15px;
            line-height: 49px;
            color: #000000;
            @media (max-width: 767px){
              line-height: 28px ;
              img{
                margin-left: 5px;
              }
            }
          }
          .client-desc-text{
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            text-transform: capitalize;
            color: #000000;
          }
        }
        .features-documents-wrapper{
          column-gap: 120px;
          @media(min-width: 992px) and (max-width: 1199px){
            column-gap: 50px;
          }
          @media(max-width: 991px){
            column-gap: 0;
          }
          @media(max-width: 767px){
            flex-direction: column;
            row-gap: 25px;
          }
          .application{
            @media(min-width: 992px) and (max-width: 1199px){
              width: 350px;
            }
            @media(max-width: 991px){
              width: 100%;
            }
            .client-desc-label{
              font-weight: normal;
              font-size: 15px;
              line-height: 49px;
              color: #000000;
              @media (max-width: 767px){
                line-height: 28px ;
                img{
                  margin-left: 5px;
                }
              }
            }
            .client-desc-text{
              font-weight: 600;
              font-size: 17px;
              line-height: 24px;
              text-transform: capitalize;
              color: #000000;
            }
          }
          .documents{
            .client-desc-label{
              font-weight: normal;
              font-size: 16px;
              line-height: 49px;
              color: #000000;

            }
            .documents-text{
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
              text-decoration-line: underline;
              color: #000000;
              margin: 0 10px;
              word-break: break-all;
            }
          }
        }

      }
    }
  }

}

