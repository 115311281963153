.add-team-wrapper{
  width: 100%;
  @media( max-width: 768px){
    padding: 0;
    width: 100%;
    background: #fff;
  }
  .header-left{
    margin-bottom: 25px;
    @media(max-width: 991px){
      margin: 25px 15px;
    }
    .back-button{
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #000000;
      span{
        margin-right: 5px;
      }
    }
  }
  .blog-title-wrapper{
    background: #000;
    color: #fff;
    padding: 22px 33px;
    border-radius: 13px 13px 0 0;
    @media( max-width: 768px){
      padding: 22px 20px;
      margin: 0 15px;
    }
    @media( max-width: 480px){
      flex-direction: column;
      row-gap: 20px;
    }
    .blog-title{
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #fff;
      margin-bottom: 0;
      @media (max-width: 767px){
        text-align: center;
        //margin-bottom: 25px;
      }
      img{
        margin-right: 10px;
      }
    }
    .member-button{
      background: #E5C243;
      border-radius: 53px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      text-transform: capitalize;
      color: #000000;
      padding: 10px 15px;
      border: none;
      &:focus{
        box-shadow: unset;
      }
      &:active{
        border-color: #E5C243;
        background: #E5C243;
        color: #000000; 
      }
      .member-button-icon{
        margin-right: 5px;
      }
    }
  }
  .blog-card-main{
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 0 0 13px 13px;
    padding: 50px 0 30px;
    margin-bottom: 50px;
    @media(max-width: 767px){
      padding: 10px 0 0;
      margin: 0 15px;
    }
    .add-blog-card{
      padding: 0 50px;
      @media (min-width: 768px) and (max-width: 991px){
        padding: 0 40px;
      }
      @media(max-width: 767px){
        padding: 20px 20px 0;
      }
      .add-blog-img-wrapper{
        column-gap: 45px;
        @media(max-width: 991px){
          flex-direction: column;
        }
        .blog-img-upload{
          @media(max-width: 767px){
            width: 100%;
          }
          .add-banner-text{
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 12px;
          }
          .add-blog-user-wrapper{
            border: 2px dashed #D4D4D4;
            box-sizing: border-box;
            border-radius: 27px;
            padding: 26px 50px;
            column-gap: 45px;
            @media (min-width: 768px) and (max-width: 991px){
              padding: 25px 30px;
              column-gap: 30px;
            }
            @media (max-width: 767px){
              flex-direction: column;
              padding: 20px 20px;
            }
            .blog-upload-box{
              .blog-upload-type{
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
                margin-bottom: 15px;
                @media(max-width: 767px){
                  text-align: center;
                  margin-top: 15px;
                }
              }
              .blog-upload-drag{
                font-weight: normal;
                font-size: 15px;
                line-height: 23px;
                color: #000000;
                margin-bottom: 5px;
                @media(max-width: 767px){
                  text-align: center;
                }
              }
              .blog-or-text{
                font-weight: normal;
                font-size: 15px;
                line-height: 23px;
                color: #000000;
                margin-bottom: 15px;
              }
              .blog-upload-buttons{
                @media(max-width: 767px){
                  display: flex;
                  flex-direction: column;
                  row-gap: 25px;
                }
                .upload-btn-choose{
                  background: #E5C243;
                  border-radius: 38px;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  color: #000000;
                  padding: 6px 20px;
                  border: none;
                }
                .upload-btn-gallery{
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  color: #000000;
                  padding: 6px 20px;
                  border: 2px solid rgba(229, 194, 67, 1);
                  background: #FFFFFF;
                  border-radius: 38px;
                  margin-left: 12px;
                  @media(max-width: 767px){
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }

      .blog-form{
        .span-number{
          width: 45px;
          height: 45px;
          border-radius: 50%;
          background: #000;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          font-size: 25px;
          line-height: 24px;
          text-align: center;
          text-transform: capitalize;
        }
        .blog-name-wrapper{
          column-gap: 25px;
          margin-bottom: 50px;
          padding-bottom: 30px;
          border-bottom: 1px solid #C4C4C4;
          @media(max-width: 1200px){
            margin-top: 0;
            flex-direction: column;
          }
          .blog-fname{
            width : 100%;
            @media(max-width: 767px){
              margin-bottom: 0;
            }
          }
          .blog-lname{
            width : 100%;
            @media(max-width: 767px){
              margin-bottom: 0;
            }
          }
          .blog-email{
            width : 100%;
            @media(max-width: 767px){
              margin-bottom: 0;
            }
          }
          .blog-department{
            width : 100%;
            @media(max-width: 767px){
              margin-bottom: 0;
            }
          }
          .blog-project{
            width : 100%;
            @media(max-width: 767px){
              margin-bottom: 0;
            }
          }
        }
        .blog-title-form{
          margin-top: 30px;
        }
        .blog-title-category{
          column-gap: 25px;
          margin-top: 30px;
          @media (min-width: 768px) and (max-width: 991px){
            margin-top: 20px;
          }
          @media (max-width: 767px){
            flex-direction: column;
            margin-top: 5px;
          }
          .blog-sub-title{
            width: 100%;
          }
          .blog-category{
            width: 100%;
          }
        }
        .publish-type{
          min-width: 270px;
          .btn-toggle-wrapper {
            width: 285px;
            @media(max-width: 767px){
              width: 100%;
            }
            .custom-toggle-button{
              padding-left: 0;
              height: 40px;
              border: 2px solid #D4D4D4;
              box-sizing: border-box;
              border-radius: 20px !important;
              .btn-default {
                padding: 0 12px;
                margin-bottom: 0;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                align-items: center;
                display: flex;
                @media(max-width: 320px){
                  font-size: 14px;
                }
              }
              .active{
                background: #E5C243;
                color: #000;
                border-radius: 20px;
                border: 1px solid transparent;
                padding: 0 12px;
                font-weight: 500;
                align-items: center;
                display: flex;
                
              }
            }
          }
        }
        .blog-type-date{
          column-gap: 25px;
          margin-top: 40px;
          @media (min-width: 768px) and (max-width: 991px){
            margin-top: 30px;
          }
          @media(max-width: 767px){
            flex-direction: column;
            margin-top: 5px;
          }
          .publish-type{
            width: 100%;
          }
          .publishing-date{
            width: 100%;
            .blog-date{
              background: #FFFFFF !important;
              border: 2px solid #D4D4D4 !important;
              box-sizing: border-box;
              border-radius: 38px;
              height: 40px;
              width: 270px;
              @media(max-width: 767px){
                width: 100%;
              }
            }
          }
        }
        .blog-summary-content{
          .blog-summary{
            margin-top: 40px;
            @media (max-width: 991px){
              margin-top: 30px;
            }
            @media(max-width: 767px){
              margin-top: 35px;
            }
          }
          .blog-content{
            margin-top: 30px;
            @media(max-width: 767px){
              margin-top: 35px;
            }
          }
        }
        .blog-form-group{
          margin-bottom: 0;
          .blog-label{
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
          }
          .select-project.ant-select{
            .ant-select-selector{
              background: #FFFFFF;
              border: 2px solid #D4D4D4;
              box-sizing: border-box;
              border-radius: 38px;
              height: 38px;
              overflow: auto;
            }
          }
          .blog-input{
            background: #FFFFFF;
            border: 2px solid #D4D4D4;
            box-sizing: border-box;
            border-radius: 38px;
            height: 38px;
            &:focus{
              box-shadow: unset;
            }
          }
          .blog-select{
            background: #FFFFFF;
            border: 2px solid #D4D4D4;
            box-sizing: border-box;
            border-radius: 38px;
            height: 38px;
            width: 100%;
            padding: 6px 12px;
            &:focus{
              box-shadow: unset;
            }
          }
          .blog-drop-select{
            background: #FFFFFF;
            border: 2px solid #D4D4D4;
            box-sizing: border-box;
            border-radius: 38px;
            width: 100%;
            &:focus{
              box-shadow: unset;
            }
            .css-1pahdxg-control{
              border-color: transparent;
              box-shadow: unset;
              border-radius: 50%;
              height: 30px;
            }
            .css-1s2u09g-control{
              background-color: transparent;
              border-color: transparent;
              box-shadow: unset;
            }
            .css-1rhbuit-multiValue{
              background-color: transparent;
              width: 40px;
              .css-12jo7m5{
                padding-right: 0;
              }
              .css-xb97g8{
                padding-left: 0;
              }
            }
            .css-g1d714-ValueContainer{
              width: 100%;
              height: 35px;
              overflow: hidden;
            }
            .css-26l3qy-menu{
              display: block !important;
            }
            .css-26l3qy-menu{
              .css-1n7v3ny-option{
                background: #fff  ;
                input[type=checkbox]{
                  //color: red;
                  &:checked{
                    background: #E5C243;
                  }
                }
                lable{
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 22px;
                  color: #000000;
                  margin-left: 15px;
                }
              }
              .css-9gakcf-option{
                background-color: transparent;
                color: #000;

              }
            }
          }
          .blog-editor{
            .tox-tinymce{
              background: #FFFFFF;
              border: 2px solid #D4D4D4;
              box-sizing: border-box;
              border-radius: 24px;
              .tox-statusbar{
                display: none;
              }
            }
          }
        }
      }
    }
    .add-blog-buttons{
      padding: 18px 54px;
      background: #FFFFFF;
      border-radius: 13px;
      @media (max-width: 767px){
        padding: 20px 0;
        flex-direction: column-reverse;
        row-gap: 19px;
      }
      .button-group{
        width: 100%;
        @media(max-width: 767px){
          flex-direction: row;
          column-gap: 10px;
        }
        @media (max-width: 432px){
          flex-direction: column;
          row-gap: 19px;
        }
        .button-cancel{
          border: 2px solid #E5C243;
          box-sizing: border-box;
          border-radius: 53px;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          padding: 11px 28px;
          background: transparent;
          &:focus{
            box-shadow: unset;
          }
          @media (min-width: 768px) and (max-width: 991px){
            padding: 10px 25px;
            font-size: 18px;
          }
          @media(max-width: 767px){
            padding: 10px 45px;
          }
          @media(max-width: 432px){
            width: 100%;
          }
        }
        .blog-invite{
          background: #E5C243;
          border-radius: 53px;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          padding: 13px 24px;
          margin-right: 15px;
          border: none;
          &:focus{
            box-shadow: unset;
          }
          img{
            margin-right: 10px;
          }
          @media (min-width: 768px) and (max-width: 991px){
            padding: 12px 20px;
            font-size: 18px;
          }
          @media (max-width: 767px){
            padding: 10px 30px;
            margin-right: 0;
          }
          @media(max-width: 432px){
            width: 100%;
          }
        }

      }
      .button-publish{
        background: #E5C243;
        border-radius: 53px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        padding: 16px 24px;
        border: none;
        margin-left: 20px;
        &:focus{
          box-shadow:unset !important;
          background: #E5C243;
          color: #000;
        }
        @media (min-width: 768px) and (max-width: 991px){
          padding: 10px 20px;
          font-size: 18px;
          margin-left: 20px;
        }
        @media(max-width: 767px){
          margin-left: 0;
        }
      }
    }
  }
  .qa-btn-notans {
    background: #ffffff;
    border: 1px solid #3e6295;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 15px;
    line-height: 24px;
    color: #162642 !important;
    height: 30px;
    opacity: 0.55;
    margin-right: 10px;
    width: 120px;
    &:hover {
      border: 1px solid transparent;
      background: #7c8e54;
    }
  }
  .ant-picker-input {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
}