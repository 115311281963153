.add-blog-wrapper{
  @media (min-width: 768px) and (max-width: 991px){
    width: 100%;
    padding: 0 50px;
    margin: 50px 0;
  }
  @media( max-width: 767px){
    padding: 0;
    width: 100%;
    background: #fff;
    padding-top: 50px;
  }
  .blog-title-wrapper{
    @media( max-width: 767px){
      padding: 0 20px;
    }
    .blog-title{
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #000000;
      margin-bottom: 15px;
      @media (max-width: 767px){
        text-align: center;
         margin-bottom: 25px;
      }
    }
  }
  .blog-card-main{
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    padding: 50px 0 0;
    @media(max-width: 767px){
      background: transparent;
      box-shadow: unset;
      border-radius: unset;
      padding: 10px 0 0;
    }
    .add-blog-card{
      padding: 0 50px;
      @media (min-width: 768px) and (max-width: 991px){
        padding: 0 40px;
      }
      @media(max-width: 767px){
        padding: 20px 20px 0;
      }
      .add-blog-img-wrapper{
        column-gap: 45px;
        @media(max-width: 991px){
          flex-direction: column;
        }
        .profile-img{}
        .blog-img-upload{
          @media(max-width: 767px){
            width: 100%;
          }
          .add-banner-text{
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 12px;
          }
          .add-blog-user-wrapper{
            border: 2px dashed #D4D4D4;
            box-sizing: border-box;
            border-radius: 27px;
            padding: 26px 50px;
            column-gap: 45px;
            @media (min-width: 768px) and (max-width: 991px){
              padding: 25px 30px;
              column-gap: 30px;
            }
            @media (max-width: 767px){
              flex-direction: column;
              padding: 20px 20px;
            }
            .blog-upload-box{
              .blog-upload-type{
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
                margin-bottom: 15px;
                @media(max-width: 767px){
                  text-align: center;
                  margin-top: 15px;
                }
              }
              .blog-upload-drag{
                font-weight: normal;
                font-size: 15px;
                line-height: 23px;
                color: #000000;
                margin-bottom: 5px;
                @media(max-width: 767px){
                  text-align: center;
                }
              }
              .blog-or-text{
                font-weight: normal;
                font-size: 15px;
                line-height: 23px;
                color: #000000;
                margin-bottom: 15px;
              }
              .blog-upload-buttons{
                @media(max-width: 767px){
                  display: flex;
                  flex-direction: column;
                  row-gap: 25px;
                }
                .upload-btn-choose{
                  background: #E5C243;
                  border-radius: 38px;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  color: #000000;
                  padding: 6px 20px;
                  border: none;
                }
                .upload-btn-gallery{
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  color: #000000;
                  padding: 6px 20px;
                  border: 2px solid rgba(229, 194, 67, 1);
                  background: #FFFFFF;
                  border-radius: 38px;
                  margin-left: 12px;
                  @media(max-width: 767px){
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }

      .blog-form{
        .blog-name-wrapper{
          column-gap: 50px;
          margin-top: 50px;
          @media (min-width: 768px) and (max-width: 991px){
            margin-top: 30px;
          }
          @media(max-width: 767px){
            margin-top: 0;
            flex-direction: column;
          }
          .blog-fname{
            width : 100%;
            @media(max-width: 767px){
              margin-bottom: 0;
            }
          }
          .blog-lname{
            width : 100%;
            @media(max-width: 767px){
              margin-bottom: 0;
            }
          }
        }
        .blog-title-form{
          margin-top: 30px;
        }
        .blog-title-category{
          column-gap: 25px;
          margin-top: 30px;
          @media (min-width: 768px) and (max-width: 991px){
            margin-top: 20px;
          }
          @media (max-width: 767px){
            flex-direction: column;
            margin-top: 5px;
          }
          .blog-sub-title{
            width: 100%;
          }
          .blog-category{
            width: 100%;
          }
        }
        .publish-type{
          min-width: 270px;
          .btn-toggle-wrapper {
            width: 285px;
            @media(max-width: 767px){
              width: 100%;
            }
            .custom-toggle-button{
              padding-left: 0;
              height: 40px;
              border: 2px solid #D4D4D4;
              box-sizing: border-box;
              border-radius: 20px !important;
              .btn-default {
                padding: 0 12px;
                margin-bottom: 0;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                align-items: center;
                display: flex;
                @media(max-width: 320px){
                  font-size: 14px;
                }
              }
              .active{
                background: #E5C243;
                color: #000;
                border-radius: 20px;
                border: 1px solid transparent;
                padding: 0 12px;
                font-weight: 500;
                align-items: center;
                display: flex;
                position: relative;
                z-index: 0;
              }
            }
          }
        }
        .blog-type-date{
          column-gap: 25px;
          margin-top: 40px;
          @media (min-width: 768px) and (max-width: 991px){
            margin-top: 30px;
          }
          @media(max-width: 767px){
            flex-direction: column;
            margin-top: 5px;
          }
          .ant-switch-checked {
            background-color: #e4c144;
          }
          .publish-type{
            width: 100%;
          }
          .publishing-date{
            width: 100%;
            .blog-date{
              background: #FFFFFF !important;
              border: 2px solid #D4D4D4 !important;
              box-sizing: border-box;
              border-radius: 38px;
              height: 40px;
              width: 270px;
              @media(max-width: 767px){
                width: 100%;
              }
            }
          }
        }
        .blog-summary-content{
          .blog-summary{
            margin-top: 40px;
            @media (max-width: 991px){
              margin-top: 30px;
            }
            @media(max-width: 767px){
              margin-top: 35px;
            }
          }
          .blog-content{
            margin-top: 30px;
            @media(max-width: 767px){
              margin-top: 35px;
            }
          }
        }
        .blog-form-group{
          //margin-top: 30px;
          margin-bottom: 0;
          @media (max-width: 991px){
            margin-top: 20px;
          }
          @media (max-width: 767px){
            margin-top: 35px;
          }
          .blog-label{
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
          }
          .media-label{
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 0;
          }
          .media-group{
            @media (max-width:767px){
              margin-bottom: 15px;
            }
            @media (max-width:375px){
              flex-direction: column;
              row-gap: 15px;
              width: 100%;
            }
            .save-media{
              background: #E5C243;
              border-radius: 38px;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: #000000;
              padding: 6px 20px;
              border: none;
              margin-right: 15px;
              @media (max-width:375px){
                margin-right: 0;
                width: 100%;
                justify-content: center;
              }
              .save-icon{
                margin-right: 5px;
              }
            }
            .upload-media{
              background: #E5C243;
              border-radius: 38px;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: #000000;
              padding: 6px 20px;
              border: none;
              @media (max-width:375px){
                width: 100%;
                justify-content: center;
              }
              .upload-icon{
                margin-right: 5px;
              }
            }
          }
          .blog-input{
            background: #FFFFFF;
            border: 2px solid #D4D4D4;
            box-sizing: border-box;
            border-radius: 38px;
            height: 38px;
          }
          .blog-select{
            background: #FFFFFF;
            border: 2px solid #D4D4D4;
            box-sizing: border-box;
            border-radius: 38px;
            height: 38px;
          }
          .blog-textarea{
            background: #FFFFFF;
            border: 2px solid #D4D4D4;
            box-sizing: border-box;
            border-radius: 24px;
            height: 95px;
          }
          .blog-editor{
            .tox-tinymce{
              background: #FFFFFF;
              border: 2px solid #D4D4D4;
              box-sizing: border-box;
              border-radius: 24px;
              .tox-statusbar{
                display: none;
              }
            }
          }
        }
      }
    }
    .imageBoxCon {
      width: 125px;
      height: 125px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 10px;
      border-radius: 50%;
      margin-bottom: 20px;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.26);
      .cameraIcon {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    .add-blog-buttons{
      padding: 18px 54px;
      background: #FFFFFF;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 13px;
      margin: 40px 0 80px;
      @media (max-width: 767px){
        padding: 20px 40px;
        flex-direction: column-reverse;
        row-gap: 19px;
      }
      .button-group{
        width: 100%;
        @media(max-width: 767px){
          flex-direction: row;
        }
        @media (max-width: 375px){
          flex-direction: column;
          row-gap: 19px;
        }
        .button-cancel{
          border: 2px solid #E5C243;
          box-sizing: border-box;
          border-radius: 53px;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          padding: 15px 30px;
          background: transparent;
          @media (min-width: 768px) and (max-width: 991px){
            padding: 10px 25px;
            font-size: 18px;
          }
          @media(max-width: 767px){
            padding: 10px 45px;
          }
          @media(max-width: 375px){
            width: 100%;
          }
        }
        .button-save{
          background: #E5C243;
          border-radius: 53px;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          padding: 16px 24px;
          border: none;
          @media (min-width: 768px) and (max-width: 991px){
            padding: 10px 20px;
            font-size: 18px;
          }
          @media (max-width: 767px){
            padding: 10px 30px;
          }
          @media(max-width: 375px){
            width: 100%;
          }
        }

      }
      .button-publish{
        background: #E5C243;
        border-radius: 53px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        padding: 16px 24px;
        border: none;
        margin-left: 20px;
        &:focus{
          box-shadow:unset !important;
          background: #E5C243;
          color: #000;
        }
        @media (min-width: 768px) and (max-width: 991px){
          padding: 10px 20px;
          font-size: 18px;
          margin-left: 20px;
        }
        @media(max-width: 767px){
          margin-left: 0;
        }
      }
    }
  }
  .qa-btn-notans {
    background: #ffffff;
    border: 1px solid #3e6295;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 15px;
    line-height: 24px;
    color: #162642 !important;
    height: 30px;
    opacity: 0.55;
    margin-right: 10px;
    width: 120px;
    &:hover {
      border: 1px solid transparent;
      background: #7c8e54;
    }
  }
  .ant-picker-input {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
}

.tox-tinymce-aux{
  display: none;
}