.perk-list-main {
  width: 100%;
  .perk-list-wrapper{
    @media (max-width: 991px){
      padding: 0;
      width: 100%;
    }
    .perk-list-section{
      @media(max-width: 991px){
        margin: 50px 0 33px;
        row-gap: 35px;
      }
      .perk-list-icon-wrapper{
        .perk-list-icon-text{
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-transform: capitalize;
          color: #000000;
          padding-left: 15px;
        }
      }
      .perk-list-button{
        background: #E5C243;
        border-radius: 34px;
        font-weight: 500;
        font-size: 15px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        margin-left: 15px;
        border: none;
        padding:  6px 14px;

        .perk-list-button-img{
          padding-right: 10px;
        }
      }
    }
  }
  .perk-list-desktop-wrap{
    display: block;
    .main-table-wrapper {
      .table-header {
        background: #000000;
        border-radius: 14px;
        height: 53px;
        color: #FFFFFF;
        margin: 40px 0;
        padding: 0 22px;
        @media(min-width: 991px) and (max-width: 1199px){
          padding: 0 12px;
        }
        .th-data {
          font-size: 15px;
          line-height: 24px;
          cursor: pointer;
          color: #fff;
          @media (min-width: 992px) and (max-width: 1199px){
            font-size: 14px;
          }
          &:first-child{
            flex: 0.6 1;
          }
          .img-icon {
            height: 8px;
            margin-left: 8px;
          }
          &:first-child {
            display: flex;
            justify-content: flex-start;
          }
          flex: 1;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
    .table-body {
      background: #FFFFFF;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 13px;
      height: 60px;
      margin-bottom: 30px;
      cursor: pointer;
      padding: 0 22px;
      @media (min-width: 992px) and (max-width: 1199px){
        padding: 0 12px;
      }
      @media(max-width: 991px){
        height: auto;
        flex-direction: column;
        padding: 50px 22px;
        margin: 0 22px 35px;
      }

      .table-data {
        display: flex;
        align-items: center;
        flex: 1;
        img {
          height: 48px;
          width: 48px;
          margin-right: 0px;
          border-radius: 50%;
        }
        &:first-child{
          flex: 0.6 ;
          @media(max-width: 991px){
            //width: 100%;
            margin-bottom: 15px;
          }
        }

        .perk-list-card{
          @media(max-width: 991px){
            width: 300px;
            justify-content: unset;
            margin-bottom: 20px;
          }
          @media (max-width: 320px){
            width: 100%;
            flex-direction: column;
            align-items: center;
            row-gap: 5;
          }
          .table-data-label{
            @media(max-width: 991px){
              width: 120px;
            }
            @media (max-width: 320px){
              width: unset;
            }
          }
          .value{
            background: rgba(107, 209, 0, 0.34);
            border-radius: 34px;
            font-weight: 500;
            font-size: 12px;
            line-height: 27px;
            text-align: center;
            text-transform: capitalize;
            color: #53A200;
            padding: 0 12px;
          }
          .website{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
          }
        }

        .perk-list-button{
          background: rgba(107, 209, 0, 0.34);
          border-radius: 34px;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #53A200;
          padding: 0 11px;
          border: none;
          @media (max-width: 991px){
            margin-bottom: 25px;
          }
        }
        .perk-list-button-draft{
          background: rgba(242, 72, 34, 0.34);
          border-radius: 34px;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #F24822;
          padding: 0 11px;
          border: none;
        }
        .action-img{
          width: 48px;
          margin: 0 5px;
        }
        .username {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
          @media(max-width: 991px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 37px;
          }
          .perk-list-img{
            width: 38px;
            margin-right: 15px;
            @media(min-width: 992px) and (max-width: 1199px){
              width: 33px;
              margin-right: 5px;
            }
            @media (max-width: 991px){
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
      .perk-list-action{
        @media (max-width: 991px){
          margin-top: 10px;
        }
      }
    }
  }
}
