.team-tab-main {
  width: 100%;
  .team-tabs-wrapper {
    
    .ant-tabs-nav {
      margin: 0 0 30px;
      background: #ffffff;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 13px;
      padding: 0 35px;
      .ant-tabs-nav-wrap {
        height: 80px;
        .ant-tabs-tab {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #24334e;
        }
        .ant-tabs-tab-active {
          font-size: 17px;
          line-height: 24px;

          .ant-tabs-tab-btn {
            font-weight: 500;
            color: #162642;
          }
        }
        .ant-tabs-ink-bar {
          background: #e5c243;
          height: 4px;
        }
      }
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      .ant-tabs-nav {
        width: 100%;
        flex-direction: column-reverse;
        .ant-tabs-nav-wrap {
          justify-content: center;
        }
      }
    }
    @media (min-width: 768px) and (max-width: 991px) {
      .ant-tabs-nav {
        width: 100%;
      }
    }
    @media (max-width: 767px) {
      .ant-tabs-nav {
        height: 70px;
        width: 100%;
        background: #ffffff;
        border-top: 0.4px solid #8d8d8d;
        border-bottom: 0.4px solid #8d8d8d;
        border-radius: 5px;
        overflow-y: hidden;
        overflow-x: auto;
        position: relative;
        .ant-tabs-nav-wrap {
          overflow: unset;
          height: 69px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .team-tabs-section {
    display: flex;
    align-items: center;

    .team-tabs-button {
      display: flex;
      column-gap: 15px;
      .assigned-project{
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 36px;
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        color: #162642;
        padding: 5px 15px;
        width: 185px;
      }
      .project-wrap{
        position: relative;
        .projects-name{
          font-weight: 400;
          font-size: 12px;
          line-height: 0;
          color: rgba(22, 38, 66, .84);
          background: #fff;
          position: absolute;
          top: 0;
          left: 11px;
          padding: 1px 5px;
        }
        .satus{
          border: 1px solid #000000;
          box-sizing: border-box;
          border-radius: 36px;
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;
          color: #162642;
          padding: 9px 15px;
          background: #fff;
          width: 160px;
        }
      }
      .satus{
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 36px;
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        color: #162642;
        padding: 5px 15px;
        background: #fff;
        width: 160px;
      }
      .team-btn {
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 36px;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        color: #162642;
        background: #fff;
        margin: 0 6px;
      }
    }
    .team-input-wrapper {
      position: relative;
      margin-left: 6px;
      @media (max-width: 991px) {
        width: 55%;
        height: 100%;
      }
      .team-input-img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5%;
      }
      .team-input {
        border: 1px solid #000000;
        border-radius: 36px;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        padding: 8px 15px 8px 35px;
        width: 145px;
        &::placeholder {
          color: #162642;
        }
        @media (max-width: 991px) {
          width: 100%;
        }
      }
    }
    .slider-wrap {
      width: 100%;
      .ant-slider {
        margin: 0 !important;
      }
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      padding-top: 25px;
    }
    @media (max-width: 767px) {
      padding: 30px 15px;
      row-gap: 25px;
    }
  }
}
.team-tabs-main {
  .investor-desktop-wrap {
    display: block;
    .main-table-wrapper {
      .table-header {
        background: #000000;
        border-radius: 14px;
        height: 53px;
        color: #ffffff;
        margin: 40px 0;
        padding: 0 22px;
        .th-data {
          font-size: 15px;
          line-height: 24px;
          cursor: pointer;
          color: #fff;
          &:first-child {
            flex: 0.25 1;
          }
          .img-icon {
            height: 8px;
            margin-left: 8px;
          }
          &:first-child {
            display: flex;
            justify-content: flex-start;
          }
          flex: 1;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
  }

  .table-body {
    background: #ffffff;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    height: 60px;
    margin-bottom: 30px;
    cursor: pointer;
    padding: 0 22px;
    @media (max-width: 991px) {
      height: auto;
      flex-direction: column;
      padding: 22px;
      margin: 0 22px;
    }
    .table-data {
      display: flex;
      align-items: center;
      flex: 1;
      @media (min-width: 991px) and (max-width: 1199px) {
        flex: 2;
      }
      &:first-child {
        flex: 0.2;
        //@media(max-width: 991px){
        //  width: 100%;
        //}
      }
      .blog-card {
        @media (max-width: 991px) {
          width: 300px;
          //justify-content: space-between;
          margin-bottom: 20px;
        }
        @media (max-width: 320px) {
          width: 200px;
        }
        .date {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
        }
        .technology {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
          @media (min-width: 991px) and (max-width: 1199px) {
            font-size: 15px;
          }
        }
        .job {
          font-weight: 500;
          font-size: 17px;
          line-height: 27px;
          text-transform: capitalize;
          color: #000000;
          @media (min-width: 991px) and (max-width: 1199px) {
            font-size: 16px;
          }
        }
      }

      .blog-button {
        background: rgba(107, 209, 0, 0.34);
        border-radius: 34px;
        font-weight: 500;
        font-size: 12px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #53a200;
        padding: 0 11px;
        border: none;
        @media (max-width: 991px) {
          margin-bottom: 25px;
        }
      }
      .blog-button-draft {
        background: rgba(242, 72, 34, 0.34);
        border-radius: 34px;
        font-weight: 500;
        font-size: 12px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #f24822;
        padding: 0 11px;
        border: none;
      }
      .action-img {
        width: 39px;
        margin: 0 5px;
      }
      .username {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #162642;
        @media (min-width: 991px) and (max-width: 1199px) {
          font-size: 15px;
        }
        @media (max-width: 991px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 37px;
        }
        .blog-img {
          width: 38px;
          margin-right: 15px;
          @media (min-width: 992px) and (max-width: 1199px) {
            width: 33px;
            margin-right: 5px;
          }
          @media (max-width: 991px) {
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
      span {
        height: 41px;
        width: 41px;
        background: #3e6295;
        border-radius: 50%;
        font-weight: 500;
        font-size: 23px;
        line-height: 24px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.4;
      }
    }
    .blog-action {
      @media (max-width: 991px) {
        margin-top: 10px;
      }
    }
  }
}
.invite-team-main {
  .blog-wrapper {
    margin: 0 auto;
    @media (max-width: 991px) {
      padding: 0;
      width: 100%;
    }
    .blog-section {
      @media (max-width: 991px) {
        margin: 50px 0 33px;
        row-gap: 35px;
      }
      .blog-icon-wrapper {
        .blog-icon-text {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-transform: capitalize;
          color: #000000;
          padding-left: 15px;
        }
      }
      .blog-input-warpper {
        position: relative;
        @media (max-width: 991px) {
          width: 55%;
          height: 100%;
        }
        .blog-input-img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 5%;
        }
        .blog-input {
          border: 1px solid #000000;
          border-radius: 36px;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          padding: 8px 25px 8px 39px;
          &::placeholder {
            color: #162642;
          }
          @media (max-width: 991px) {
            width: 100%;
          }
        }
      }
      .blog-button {
        background: #e5c243;
        border-radius: 34px;
        font-weight: 500;
        font-size: 15px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        margin-left: 15px;
        border: none;
        padding: 6px 14px;

        .blog-button-img {
          padding-right: 10px;
        }
      }
    }
  }
  .tab-desktop-wrap {
    display: block;
    .main-table-wrapper {
      .table-header {
        background: #000000;
        border-radius: 14px;
        height: 53px;
        color: #ffffff;
        margin: 40px 0;
        padding: 0 22px;
        @media (min-width: 991px) and (max-width: 1199px) {
          padding: 0 12px;
        }
        .th-data {
          font-size: 15px;
          line-height: 24px;
          cursor: pointer;
          color: #fff;
          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 14px;
          }
          &:first-child {
            flex: 1 1;
          }
          .img-icon {
            height: 8px;
            margin-left: 8px;
          }
          &:first-child {
            display: flex;
            justify-content: flex-start;
          }
          flex: 1;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
    .table-body {
      background: #ffffff;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 13px;
      height: 60px;
      margin-bottom: 30px;
      cursor: pointer;
      padding: 0 22px;
      @media (min-width: 992px) and (max-width: 1199px) {
        padding: 0 12px;
      }
      @media (max-width: 991px) {
        height: auto;
        flex-direction: column;
        padding: 22px;
      }

      .table-data {
        display: flex;
        align-items: center;
        flex: 1;
        &:first-child {
          flex: 1 1;
          //@media(max-width: 991px){
          //  width: 100%;
          //}
        }

        .blog-card {
          @media (max-width: 991px) {
            width: 300px;
            justify-content: unset;
            margin-bottom: 20px;
          }
          @media (max-width: 320px) {
            width: 200px;
          }
          .table-data-label {
            @media (max-width: 991px) {
              width: 160px;
            }
          }
          .project {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background: #e5c243;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .technology {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
            @media (min-width: 991px) and (max-width: 1199px) {
              font-size: 15px;
            }
          }
          .job {
            font-weight: 500;
            font-size: 17px;
            line-height: 27px;
            text-transform: capitalize;
            color: #000000;
            @media (min-width: 991px) and (max-width: 1199px) {
              font-size: 16px;
            }
          }
          .blog-button {
            background: #e5c243;
            border-radius: 34px;
            font-weight: 500;
            font-size: 15px;
            line-height: 27px;
            text-align: center;
            text-transform: capitalize;
            color: #000000;
            margin-left: 15px;
            border: none;
            padding: 6px 14px;
            img {
              margin-right: 10px;
            }
            @media (min-width: 992px) and (max-width: 1199px) {
              margin-left: 0;
              font-size: 14px;
              padding: 5px 10px;
            }
            @media (max-width: 991px) {
              margin-bottom: 25px;
            }
          }
        }

        .blog-button {
          background: rgba(107, 209, 0, 0.34);
          border-radius: 34px;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #53a200;
          padding: 0 11px;
          border: none;
          @media (max-width: 991px) {
            margin-bottom: 25px;
          }
        }
        .blog-button-draft {
          background: rgba(242, 72, 34, 0.34);
          border-radius: 34px;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #f24822;
          padding: 0 11px;
          border: none;
        }
        .action-img {
          width: 39px;
          margin: 0 5px;
        }
        .username {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
          @media (min-width: 991px) and (max-width: 1199px) {
            font-size: 15px;
          }
          @media (max-width: 991px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 37px;
          }
          .blog-img {
            width: 38px;
            margin-right: 15px;
            @media (min-width: 992px) and (max-width: 1199px) {
              width: 33px;
              margin-right: 5px;
            }
            @media (max-width: 991px) {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
        span {
          height: 41px;
          width: 41px;
          background: #3e6295;
          border-radius: 50%;
          font-weight: 500;
          font-size: 23px;
          line-height: 24px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0.4;
        }
      }
      .blog-action {
        @media (max-width: 991px) {
          margin-top: 10px;
        }
      }
    }
  }
}
