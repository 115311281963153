.admin-login-main {
  background: #000;
  width: 100%;
  top: 0;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  z-index: 1030;
  padding: 25px 0;
  .admin-login-img{
    padding: 0 48px;
    @media (max-width: 767px){
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
    }
  }
  .admin-login-modal {
    max-width: 506px !important;
    width: 506px;
    height: calc(100vh - 150px);
    @media (max-width: 767px){
      width: 100%;
      max-width: 100% !important;
      margin: 0;
      padding: 0 25px;
    }
    .modal-content {
      display: flex;
      align-items: center;
      padding: 50px;
      border-radius: 31px;
      top: 50%;
      transform: translateY(-50%);
      @media(max-width: 767px){
        padding: 45px 35px;
      }
      .admin-login-head-main {
        border-bottom: unset;
        .admin-login-head{
          font-weight: 500;
          font-size: 30px;
          line-height: 36px;
          color: #000000;
          @media (max-width: 767px){
            font-size: 20px;
            line-height: 36px;
          }
        }
      }
      .admin-login-modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        width: 100%;
        .admin-login-form{
          @media (max-width: 767px){
            width: 100%;
          }
          .admin-login-lable{
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 0;
            color: #000000;
          }
          .admin-login-input{
            border: 1px solid #D4D4D4;
            width: 388px;
            height: 42px;
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            &::placeholder{
              color: #000;
            }
            @media (max-width: 767px){
              width: 100%;
            }
          }
          .admin-login-password{
            position: relative;
            .admin-login-password-img{
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 15px;
            }
          }
        }
      }
      .admin-login-footer {
        border-top: unset;
        padding: 0;
        margin-top: 21px;
        flex-direction: column;
        @media (max-width: 767px){
          width: 100%;
        }
        .admin-login-forgot{
          font-weight: normal;
          font-size: 15px;
          line-height: 18px;
          text-align: right;
          color: #E5C243;
          margin: 0;
        }
        .button-signin {
          background: #E5C243;
          padding: 14px 40px;
          font-weight: bold;
          font-size: 17px;
          line-height: 20px;
          text-align: center;
          color: #000000;
          width: 393px;
          border-radius: 48px;
          border: none;
          margin-top: 35px;
          @media(max-width: 767px){
            width: 100%;
          }
        }
      }
    }
  }
}
.ant-message-custom-content {
  display: flex;
  align-items: center;
}
.validation-eror {
  color: red;
  margin-top: 5px;
}