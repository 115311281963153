.client-referral-main {
  @media (max-width: 991px){
    padding: 0;
  }
  .client-referral-desktop-wrap{
    display: block;
    position: relative;
    .total-credits{
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #162642;
      @media (min-width: 768px) and (max-width: 991px){
        margin-bottom: 20px;
      }
      @media (max-width: 767px){
        margin-bottom: 20px;
      }
    }
    .client-referral-spin{
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      .ant-spin-dot-item{
        background-color: #E5C243;
      }
    }
    .main-client-wrapper {
      .table-header {
        background: #000000;
        border-radius: 14px;
        height: 53px;
        color: #FFFFFF;
        margin: 40px 0;
        padding: 0 22px;
        @media(min-width: 991px) and (max-width: 1199px){
          padding: 0 12px;
        }
        .th-data {
          font-size: 15px;
          line-height: 24px;
          cursor: pointer;
          color: #fff;
          .img-icon {
            margin-left: 8px;
          }
          @media (min-width: 992px) and (max-width: 1199px){
            font-size: 14px;
          }
          &:nth-of-type(1){
            flex: 0.9;
          }
          &:nth-of-type(2){
            flex: 1;
          }
          &:nth-of-type(3){
            flex: 0.8;
          }
          &:nth-of-type(4){
            flex: 0.8;
          }
          &:nth-of-type(5){
            flex: 0.8;
          }
          &:nth-of-type(6){
            flex: 0.8;
          }
          &:first-child {
            display: flex;
            justify-content: flex-start;
          }
          flex: 1;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
    .table-body {
      background: #FFFFFF;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 13px;
      height: 60px;
      margin-bottom: 30px;
      cursor: pointer;
      padding: 0 22px;
      @media (min-width: 992px) and (max-width: 1199px){
        padding: 0 12px;
      }
      @media(max-width: 991px){
        height: auto;
        flex-direction: column;
        padding: 22px;
      }
      .table-data {
        display: flex;
        align-items: center;
        flex: 1;
        width: 100%;
        &:nth-of-type(1){
          flex: 0.9;
        }
        &:nth-of-type(2){
          flex: 1;
        }
        &:nth-of-type(3){
          flex: 0.8;
        }
        &:nth-of-type(4){
          flex: 0.8;
        }
        &:nth-of-type(5){
          flex: 0.8;
        }
        &:nth-of-type(6){
          flex: 0.8;
        }
        @media (max-width: 991px){
          justify-content: center;
        }
        .referral-card{
          @media(max-width: 991px){
            width: 250px;
            justify-content: unset;
            margin-bottom: 20px;
          }
          @media (max-width: 320px){
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .table-data-label{
            @media(max-width: 991px){
              width: 120px;
            }
            @media (max-width: 320px){
              width: unset;
            }
          }
          .action-buttons{
            .button-approve{
              background: #E5C243;
              border-radius: 34px;
              border: 1.5px solid transparent;
              font-weight: 500;
              font-size: 15px;
              line-height: 27px;
              text-align: center;
              text-transform: capitalize;
              color: #000000;
              padding: 4px 15px;
              margin-right: 10px;
              @media (min-width: 992px) and (max-width: 1199px){
                font-size: 12px;
                line-height: 23px;
                padding: 4px 10px;
                margin-right: 5px;
              }
              @media (max-width: 320px){
                width: 100%;
                margin-bottom: 15px;
              }
            }
            .button-reject{
              border: 1.5px solid #E5C243;
              border-radius: 34px;
              background: transparent;
              font-weight: 500;
              font-size: 15px;
              line-height: 27px;
              text-align: center;
              text-transform: capitalize;
              color: #000000;
              padding: 4px 20px;
              @media (min-width: 992px) and (max-width: 1199px){
                font-size: 12px;
                line-height: 23px;
                padding: 4px 10px;
                margin-right: 5px;
              }
              @media (max-width: 320px){
                width: 100%;
              }
            }
            .btn-approved{
              .btn-approved-icon{
                width:20px;
                height: 20px;
                border-radius: 50%;
                background: #53A100;
                color: #fff;
                font-size: 11px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .btn-approved-text{
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                color: #53A100;
                margin-left: 5px;
              }
            }
            .btn-rejected{
              .btn-rejected-icon{
                width:20px;
                height: 20px;
                border-radius: 50%;
                background: #F24822;
                color: #fff;
                font-size: 11px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .btn-rejected-text{
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
                color: #F24822;
                margin-left: 5px;
              }
            }
          }
          .credit{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
          }
          .sign-date{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
          }
          .start-date{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
          }
          .referral-email{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #2462CD;
            column-gap: 5px;
            .referral-checkout{
              background: #53A100;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              .referral-checkout-icon{
                color:#fff;
                font-size: 10px;
              }
            }
            .referral-info{
              background: #F24822;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              .referral-info-icon{
                color:#fff;
                font-size: 11px;
              }
            }
            img{
              margin-left: 5px;
            }
          }
        }
        .username {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
          @media(max-width: 991px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;
          }
          .referral-img{
            width: 48px;
            margin-right: 15px;
            @media(min-width: 992px) and (max-width: 1199px){
              width: 33px;
              margin-right: 5px;
            }
            @media (max-width: 991px){
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  .client-referral-pagination{
    margin: 50px 0 80px;
    .ant-pagination{
      .ant-pagination-item{
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        font-size: 15px;
        color: #727272;
      }
      .ant-pagination-next{
        .ant-pagination-item-link{
          background: #FFFFFF;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          font-size: 15px;
          color: #727272;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ant-pagination-prev{
        .ant-pagination-item-link{
          background: #FFFFFF;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          font-size: 15px;
          color: #727272;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ant-pagination-item-active{
        background: #E5C243;
        color: #000;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        border:none;
        font-size: 15px;
      }
    }
  }
}