.client-management-details-wrapper{
  width: 100%;
  @media(max-width: 768px){
    padding: 0 30px;
  }
  .header-left{
    margin-bottom: 25px;
    @media(max-width: 991px){
      margin: 25px 15px;
    }
    .back-button{
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #000000;
      span{
        margin-right: 5px;
      }
    }
  }
  .client-management-profile{
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    padding: 25px;
    @media (max-width: 1360px){
      flex-direction: column !important;
      row-gap: 30px;
    }
    @media(min-width: 992px) and (max-width: 1199px){
      padding: 20px 25px;
    }
    @media(min-width: 768px) and (max-width: 991px){
      row-gap: 35px;
      padding: 20px 20px;
    }
    @media(max-width: 768px){
      flex-direction: column;
      row-gap: 25px;
      padding: 35px 25px;
    }
    @media(max-width: 320px){
      padding: 35px 10px;
    }
    .project-profile{
      .user-name{
        font-size: 17px;
        line-height: 41px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
      }
      .active-btn{
        background: rgba(107, 209, 0, 0.34);
        border-radius: 34px;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        text-transform: capitalize;
        color: #53A200;
        padding: 3px 12px;
        border: unset;
      }
    }

    .client-project-details{
      column-gap: 50px;
      
      @media (min-width: 992px) and (max-width: 1199px){
        column-gap: 15px;
      }
      @media (min-width:768px) and (max-width:991px){
        column-gap: 15px;
        flex-direction: column !important;
        max-width: 360px;
        width: 100%;
        display: flex;
        align-self: center;
      }
      .project-details-wrapper{
        @media (max-width: 992px){
          flex-direction: row !important;
          align-items: center;
          justify-content: space-between !important;
        }
        .project-label{
          font-weight: normal;
          font-size: 16px;
          //line-height: 49px;
          color: #000000;
          margin-bottom: 10px;
          @media (max-width: 767px){
            width: 150px;
          }
          @media (max-width: 375px){
            width: 130px;
            font-size: 14px;
          }
        }
        .project-text{
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          text-transform: capitalize;
          color: #000000;
          @media (max-width: 375px){
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
    .project-button-wrapper{
      .project-button{
        background: #FFFFFF;
        border: 2px solid #E5C243;
        box-sizing: border-box;
        border-radius: 34px;
        font-weight: 500;
        font-size: 15px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        padding: 7px 15px;
      }
    }
  }
  .client-management-status-tab{
    .ant-tabs-nav{
      background: #FFFFFF;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      border-radius: 13px;
      margin: 35px 0 25px;
      padding: 0 40px;
      @media(min-width: 992px) and (max-width: 1199px){
        padding: 0 25px;
      }
      @media(max-width: 991px){
        padding:  0 20px;
      }
      @media (max-width: 767px){
        padding: 0;
      }
      .ant-tabs-nav-wrap{
        height: 78px;
        .ant-tabs-tab{
          font-weight: 500;
          font-size: 17px;
          line-height: 24px;
          color: #24334E;
        }
        .ant-tabs-tab-active{
          font-weight: 600;

          .ant-tabs-tab-btn{
            font-size: 17px;
            line-height: 24px;
            font-weight: 500;
            color: #162642;
          }
        }
        .ant-tabs-ink-bar{
          background:  #E5C243;
          height: 4px;
        }
      }
    }
    @media (min-width: 992px) and (max-width: 1440px){
      .ant-tabs-nav{
        width: 100%;
      }
    }
    @media (min-width: 768px) and (max-width: 991px){
      .ant-tabs-nav{
        width: 100%;
      }
    }
    @media (max-width: 767px){
      .ant-tabs-nav{
        height: 70px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 5px;
        overflow-y: hidden;
        overflow-x: auto;
        position: relative;
        .ant-tabs-nav-wrap{
          overflow: auto;
          height: 69px;
          display: flex;
          //justify-content: center;

        }
      }
    }
  }
}
.invoice-button-wrapper{
  .invoice-button{
    background: #E5C243;
    border-radius: 34px;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    border: unset;
    padding: 6px 16px;
    .invoice-button-img{
      margin-right: 8px;
    }
    &:active,
    &:hover{
      background: #E5C243;
      background-color: #E5C243 !important;
      color: #000000 !important;
    }
    &:focus{
      box-shadow: unset !important;
    }
  }
}