.change-password-main-wrapper{
  width: 100%;
  @media(max-width: 768px){
    padding: 50px 25px;
  }
  .ant-form-item-explain-error {
    font-size: 12px;
    padding-left: 5px;
  }
  .setting-text{
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000000;
  }
  .form-main-wrapper {
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    padding: 74px 20px;
    margin-top: 30px;
    .form-header-wrap {
      h6 {
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        color: #000000;
      }
    }

    .custom-form-wrapper {
      .form-content {
        width: 376px;
        //margin-right: 110px;
        .input-btn-wrapper {
          position: relative;

          .input-box {
            border: 1px solid rgba(0, 0, 0, 0.5);
            border-radius: 38px;
            height: 42px;
            font-size: 14px;
            line-height: 22px;
            color: #162642;
            margin-bottom: 0;
            &::placeholder {
              font-weight: 300;
              color: #000000;
            }
          }
          .eye-img {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            width: 22px;
            height: 22px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
        .btn-submit {
          background: #E5C243;
          border: 1px solid #E5C243;
          box-sizing: border-box;
          border-radius: 38px;
          padding: 8px 10px;
          width: 100%;
          margin-top: 35px;
          span {
            font-weight: 500;
            font-size: 17px;
            line-height: 24px;
            color: #000;
          }
        }
      }
      .change-pwd-img {
        width: 177px;
        height: 113px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width:991px){
    .form-main-wrapper {
      .form-header-wrap {
      }
      .custom-form-wrapper {

        .form-content {
          width: 100%;
          margin: 56px 0 0;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
    .form-main-wrapper {
      padding: 0 14px 0;
      background: transparent;
      .form-header-wrap {
        background: #FFFFFF;
        padding: 22px 0px;
      }
      .custom-form-wrapper {
        padding: 15px 5px 30px;
        background: #FFFFFF;

        .form-content {
          width: 100%;

          .input-btn-wrapper {
            .input-box {
              height: 42px;
            }
          }

          .btn-submit {
            margin-top: 56px;
            min-height: 46px;
          }
        }
      }
    }
  }
  @media (min-width: 426px) and (max-width: 767px){
    max-width: 100% !important;
    .form-main-wrapper{
      padding: 0 50px 0;
    }
  }
}



