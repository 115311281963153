.invite-team-member-main {
    .blog-wrapper {
      margin: 0 auto;
      @media (max-width: 991px) {
        padding: 0;
        width: 100%;
      }
      .blog-section {
          margin-bottom: 30px;
        @media (max-width: 991px) {
          margin: 50px 0 33px;
          row-gap: 35px;
        }
        .blog-icon-wrapper {
          .blog-icon-text {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-transform: capitalize;
            color: #000000;
            padding-left: 15px;
          }
        }
        .blog-input-warpper {
          position: relative;
          @media (max-width: 991px) {
            width: 55%;
            height: 100%;
          }
          .blog-input-img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 5%;
          }
          .blog-input {
            border: 1px solid #000000;
            border-radius: 36px;
            font-weight: normal;
            font-size: 13px;
            line-height: 24px;
            padding: 8px 25px 8px 39px;
            &::placeholder {
              color: #162642;
            }
            @media (max-width: 991px) {
              width: 100%;
            }
          }
        }
        .blog-button {
          background: #e5c243;
          border-radius: 34px;
          font-weight: 500;
          font-size: 15px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          margin-left: 15px;
          border: none;
          padding: 6px 14px;
          
  
          .blog-button-img {
            padding-right: 10px;
          }
        }
      }
    }
    .tab-desktop-wrap {
      display: block;
      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 25px;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 25px;
      }
      .main-table-wrapper {
          
        .table-header {
          background: #000000;
          border-radius: 14px;
          height: 53px;
          color: #ffffff;
          margin: 0 0 40px;
          padding: 0 22px;
          @media (min-width: 991px) and (max-width: 1199px) {
            padding: 0 12px;
          }
          .th-data {
            font-size: 15px;
            line-height: 24px;
            cursor: pointer;
            color: #fff;
            @media (min-width: 992px) and (max-width: 1199px) {
              font-size: 14px;
            }
            &:first-child {
              flex: 1 1;
            }
            .img-icon {
              height: 8px;
              margin-left: 8px;
            }
            &:first-child {
              display: flex;
              justify-content: flex-start;
            }
            flex: 1;
            display: flex;
            justify-content: flex-start;
          }
        }
      }
      .table-body {
        background: #ffffff;
        box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
        border-radius: 13px;
        height: 60px;
        margin-bottom: 30px;
        cursor: pointer;
        padding: 0 22px;
        &:last-child{
            margin-bottom: 0;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          padding: 0 12px;
        }
        @media (max-width: 991px) {
          height: auto;
          flex-direction: column;
          padding: 22px;
        }
  
        .table-data {
          display: flex;
          align-items: center;
          flex: 1;
          &:first-child {
            flex: 1 1;
            //@media(max-width: 991px){
            //  width: 100%;
            //}
          }
  
          .blog-card {
            @media (max-width: 991px) {
              width: 300px;
              justify-content: unset;
              margin-bottom: 20px;
            }
            @media (max-width: 320px) {
              width: 200px;
            }
            .table-data-label {
              @media (max-width: 991px) {
                width: 160px;
              }
            }
            .project {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #162642;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              background: #e5c243;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .technology {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #162642;
              white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 185px;
              @media (min-width: 991px) and (max-width: 1199px) {
                font-size: 15px;
              }
            }
            .job {
              font-weight: 500;
              font-size: 17px;
              line-height: 27px;
              text-transform: capitalize;
              color: #000000;
              @media (min-width: 991px) and (max-width: 1199px) {
                font-size: 16px;
              }
            }
            .blog-button {
              background: #e5c243;
              border-radius: 34px;
              font-weight: 500;
              font-size: 15px;
              line-height: 27px;
              text-align: center;
              text-transform: capitalize;
              color: #000000;
              margin-left: 15px;
              border: none;
              padding: 6px 14px;
              min-width: 155px;
              img {
                margin-right: 10px;
              }
              @media (min-width: 992px) and (max-width: 1199px) {
                margin-left: 0;
                font-size: 14px;
                padding: 5px 10px;
              }
              @media (max-width: 991px) {
                margin-bottom: 25px;
              }
            }
          }
  
          .blog-button {
            background: rgba(107, 209, 0, 0.34);
            border-radius: 34px;
            font-weight: 500;
            font-size: 12px;
            line-height: 27px;
            text-align: center;
            text-transform: capitalize;
            color: #53a200;
            padding: 0 11px;
            border: none;
            @media (max-width: 991px) {
              margin-bottom: 25px;
            }
          }
          .blog-button-draft {
            background: rgba(242, 72, 34, 0.34);
            border-radius: 34px;
            font-weight: 500;
            font-size: 12px;
            line-height: 27px;
            text-align: center;
            text-transform: capitalize;
            color: #f24822;
            padding: 0 11px;
            border: none;
          }
          .action-img {
            width: 39px;
            margin: 0 5px;
          }
          .username {
            column-gap: 15px;
            
            .user-icon{
              background: #E5C243;
              width: 45px;
              height: 45px;
              border-radius: 50%;
              font-weight: 700;
              font-size: 24px;
              line-height: 24px;
              color: #162642;
              margin-bottom: 0;
            }
            .user-name{
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #162642;
              margin-bottom: 0;
            }
            .blog-img{
              width: 38px;
              margin-right: 15px;
              @media(min-width: 992px) and (max-width: 1199px){
                width: 33px;
                margin-right: 5px;
              }
              @media (max-width: 991px){
                margin-right: 0;
                margin-bottom: 10px;
              }
            }
          }
          
        }
        .blog-action {
          @media (max-width: 991px) {
            margin-top: 10px;
          }
        }
      }
    }
  }