.client-main {
  width: 100%;
  .client-spin{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .ant-spin-dot-item{
      background-color: #E5C243;
    }
  }
  .client-wrapper{
    @media (max-width: 991px){
      padding: 0;
    }
    @media (max-width: 991px){
      padding: 0;
      width: 100%;
    }
    .filterBoxContainer {
      @media (max-width: 991px){
        display: flex !important;
        margin: 0 22px;
        // flex-direction: column;
        // row-gap: 20px;
      }
      @media (max-width: 600px){
        flex-direction: column-reverse;
        row-gap: 20px;
      }
    }
    .client-section{
      @media(max-width: 991px){
        margin: 50px 0 33px;
        row-gap: 35px;
      }
      .client-icon-wrapper{
        .client-icon-text{
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-transform: capitalize;
          color: #000000;
          padding-left: 15px;
        }
      }
      .btn-client-wrapper {
        @media(max-width: 600px){
          width: 100%;
        }
        .custom-toggle-button{
          padding-left: 0;
          height: 40px;
          border: 2px solid #D4D4D4;
          box-sizing: border-box;
          border-radius: 20px !important;
          box-shadow: 0px 0px 13px 0px #200E322E;
          .btn-default {
            padding: 8px 25px;
            margin-bottom: 0;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            align-items: center;
            display: flex;
            @media(max-width: 320px){
              font-size: 14px;
            }
          }
          .active{
            background: #E5C243;
            color: #000;
            //border-radius: 20px;
            border: 1px solid transparent;
            padding: 8px 25px;
            font-weight: 500;
            align-items: center;
            display: flex;
            position: relative;
            z-index: 0;
          }
        }
      }
      .client-input-wrapper{
        position: relative;
        @media(max-width: 991px){
          width: 55%;
          height: 100%;
        }
        @media(max-width: 600px){
          width: 100%;
        }
        .client-input-img{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 5%;

        }
        .client-input{
          border: 1px solid transparent;
          border-radius: 36px;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          padding: 8px 25px 8px 39px;
          box-shadow: 0px 0px 13px 0px #200E322E;
          margin-right: 21px;
          &::placeholder{
            color: #162642;
          }
          @media(max-width: 991px){
            width: 100%;
          }
        }
      }
    }
  }
  .client-desktop-wrap{
    display: block;
    @media (min-width: 992px) and (max-width: 1199px){

    }
    .main-client-wrapper {
      .table-header {
        background: #000000;
        border-radius: 14px;
        height: 53px;
        color: #FFFFFF;
        margin: 40px 0;
        padding: 0 22px;
        @media(min-width: 991px) and (max-width: 1199px){
          padding: 0 12px;
        }
        @media(max-width: 992px){
          display: none !important;
        }
        .th-data {
          font-size: 15px;
          //line-height: 24px;
          cursor: pointer;
          color: #fff;
          @media (min-width: 992px) and (max-width: 1199px){
            font-size: 14px;
          }
          &:first-child{
            flex: 0.25 1;
          }
          .img-icon {
            //height: 8px;
            margin-left: 8px;
          }
          &:first-child {
            display: flex;
            justify-content: flex-start;
          }
          flex: 1;
          display: flex;
          justify-content: flex-start;
          @media screen and (min-width: 991px) and (max-width: 1199px) {
            &:first-child {
              min-width: 20px;
            }
            &:nth-of-type(2){
              flex: 1.2;
            }
            &:nth-of-type(3){
              flex: 1.4;
            }
            &:nth-of-type(4){
              flex: 1;
            }
            &:nth-of-type(5){
              flex: 1;
            }
            &:nth-of-type(6){
              flex: 0.9;
            }
            &:nth-of-type(7){
              flex: 0.9;
            }
            &:nth-of-type(8){
              flex: 0.6;
            }
          }
         }
      }
    }
    .table-body {
      background: #FFFFFF;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 13px;
      height: 60px;
      margin-bottom: 30px;
      cursor: pointer;
      padding: 0 22px;
      @media (min-width: 992px) and (max-width: 1199px){
        padding: 0 12px;
      }
      @media(max-width: 991px){
        height: auto;
        flex-direction: column;
        padding: 22px;
      }

      .table-data {
        display: flex;
        align-items: center;
        flex: 1;
        &:first-child{
          flex: 0.2 ;
          @media(max-width: 991px){
            width: 100%;
          }
        }
        .blog-card{
          @media(max-width: 991px){
            width: 300px;
            justify-content: unset;
            margin-bottom: 20px;
          }
          @media (max-width: 320px){
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .table-data-label{
            @media(max-width: 991px){
              width: 160px;
            }
            @media (max-width: 320px){
              width: unset;
            }
          }
          .project{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background: #E5C243;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .email{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #2462CD;
            display: block;
            @media (min-width:992px) and (max-width:1199px){
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 150px;
            }
            img{
              width: 14px;
              height: 14px;
              margin-left: 5px;
            }
          }
          .date{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
          }
          .team{
            width: 26px;
            height: 26px;
            background: #E5C243;
            border-radius: 50%;
          }
          .pending{
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            color: #53A100;
            .pending-img{
              margin-right: 5px;
            }
          }
          .stage{
            font-weight: 500;
            font-size: 17px;
            line-height: 27px;
            text-transform: capitalize;
            color: #000000;

          }
        }
        .blog-button-active{
          background: rgba(107, 209, 0, 0.34);
          border-radius: 34px;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #53A200;
          padding: 0 11px;
          border: none;

        }
        .blog-button-draft{
          background: rgba(242, 72, 34, 0.34);
          border-radius: 34px;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #F24822;
          padding: 0 11px;
          border: none;
        }
        .action-img{
          width: 39px;
          margin: 0 5px;
        }
        .username {
          column-gap: 15px;
          display: flex;
          align-items: center;
        .user-icon{
          background: #E5C243;
          width: 45px;
          height: 45px;
          border-radius: 50%;
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          color: #162642;
          margin-bottom: 0;
        }
        .user-name{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
          margin-bottom: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 70%;
          @media (min-width: 991px){
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          @media (min-width:991px) and (max-width:1199px){
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 110px;
          }
        }

          @media(max-width: 991px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;
          }
          .blog-img{
            width: 48px;
            margin-right: 0px;
            border-radius: 50%;
            @media(min-width: 992px) and (max-width: 1199px){
              width: 33px;
              margin-right: 5px;
            }
            @media (max-width: 991px){
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
        @media screen and (min-width: 991px) and (max-width: 1199px) {
          //min-width: 120px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          &:first-child {
            min-width: 20px;
          }
          &:nth-of-type(2){
            flex: 1.6;
          }
          &:nth-of-type(3){
            flex: 1.4;
          }
          &:nth-of-type(4){
            flex: 1;
          }
          &:nth-of-type(5){
            flex: 1;
          }
          &:nth-of-type(6){
            flex: 0.9;
          }
          &:nth-of-type(7){
            flex: 0.9;
          }
          &:nth-of-type(8){
            flex: 0.6;
          }
        }
      }
      .blog-action{
        @media (max-width: 991px){
          margin-top: 10px;
        }
      }
    }
  }
}
.lead-pagination{
  margin-bottom: 50px;
  @media (max-width: 991px){
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .ant-pagination{
    .ant-pagination-item{
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      font-size: 15px;
      color: #727272;
    }
    .ant-pagination-next{
      .ant-pagination-item-link{
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        font-size: 15px;
        color: #727272;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-pagination-prev{
      .ant-pagination-item-link{
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        font-size: 15px;
        color: #727272;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-pagination-item-active{
      background: #E5C243;
      color: #000;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      border:none;
      font-size: 15px;
    }
  }
}
.team-filter-main{
  position: relative;
  .team-filter-wrapper{
    .btn-secondary{
      background: unset;
      border: unset;
      img{
        position: absolute;
        bottom: 0px;
        right: 15px;
        width: 100px;
      }
    }
  }
  .team-filter-menu{
    width: 100%;
    padding: 45px 30px;
    background: #FFFFFF;
    position: absolute;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.19);
    border-radius: 13px;
    border: unset;
    transform: translate3d(0, 127px, -16px) !important;
    .team-filter-cancel{
      position: relative;
      margin-bottom: 26px;
      .team-filter-text{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
      .team-cancel{
        position: absolute;
        top: -85%;
        right: -6%;
      }
    }

    .team-dashboard-button{
      display: flex;
      flex-wrap: wrap;

    }
    .team-input-wrapper{
      position: relative;
      margin-left: 6px;
      @media(max-width: 991px){
        height: 100%;
      }
      .team-input-img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5%;
        @media (max-width: 991px){
          left: 3%;
        }
      }
      .team-input{
        border: 1px solid #000000;
        border-radius: 36px;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        padding: 8px 15px 8px 45px;
        &::placeholder{
          color: #162642 !important;
        }
        @media(max-width: 991px){
          width: 100%;
        }
      }
    }
    .btn-team-wrapper {
      @media(max-width: 991px){
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
      }
      @media(max-width: 767px){
        width: 100%;
      }
      .custom-toggle-button{
        padding-left: 0;
        height: 40px;
        border: 2px solid #D4D4D4;
        box-sizing: border-box;
        border-radius: 20px !important;
        .btn-default {
          padding: 8px 35px;
          margin-bottom: 0;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          align-items: center;
          display: flex;
          @media(max-width: 320px){
            font-size: 14px;
          }
        }
        .active{
          background: #E5C243;
          color: #000;
          //border-radius: 20px;
          border: 1px solid transparent;
          padding: 8px 25px;
          font-weight: 500;
          align-items: center;
          display: flex;
          position: relative;
          z-index: 0;
        }
      }
    }
    .team-apply-btn{
      background: #E5C243;
      border-radius: 53px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: capitalize;
      color: #000000;
      margin-top: 34px;
      width: 100%;
      border: unset;
      &:focus{
        border: unset;
        box-shadow: unset;
      }
    }
  }
}
.ant-pagination-options {
  display: none !important;
}
@media(min-width: 1000px){
  .tooltipEmail {
    width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}