.client-dashboard-main{
  width: 100%;
  @media (max-width: 767px){
    padding: 0 25px;
  }
  .downloadPdfIcon {
    cursor: pointer;
  }
  
  .client-dashboard-wrapper{
    // width: 1100px;
    margin: 0 auto;
    margin-bottom: 50px;
    // padding: 0 70px 0 170px;
    // @media(min-width: 992px) and (max-width: 1199px){
    //   // width: 800px;
    //   padding: 0 50px 0 140px;
    // }
    // @media(max-width: 991px){
    //   width: 100%;
    //   padding: 50px 50px 0;
    // }
    @media(max-width: 767px){
      padding: 0;
      margin-top: 50px;
    }
    .clients-dashboard{
      margin-bottom: 30px;
      @media(min-width: 992px) and (max-width: 1199px){
        margin-bottom: 20px;
      }
      .dashboard-text{
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-transform: capitalize;
        color: #000000;
        margin-left: 15px;
        @media(min-width: 992px) and (max-width: 1199px){
          font-size: 18px;
          margin-left: 10px;
        }
      }
    }
    .clients-dashboard-projects{
      column-gap: 35px;
      @media(max-width: 991px){
        flex-direction: column;
        row-gap: 25px;
      }
      .dashboard-project{
        border: 1px solid #E5C243;
        box-shadow: 0px 2px 19px 0px #200E321F;
        border-radius: 13px;
        background: #FFFFFF;
        padding: 15px 18px;
        // width: 420px;
        width: 100%;
        @media(min-width: 992px) and (max-width: 1199px){
          width: 260px;
        }
        @media (min-width: 768px) and (max-width: 991px){
          width: 100%;
        }
        @media (max-width: 767px){
          width: 100%;
        }
        .project-number{
          background: #E5C243;
          border-radius: 13px;
          font-weight: bold;
          font-size: 35px;
          line-height: 41px;
          color: #000000;
          border: none;
          padding: 9px 20px;
          @media(min-width: 992px) and (max-width: 1199px){
            font-size: 28px;
            line-height: 28px;
            padding: 9px 15px;
          }
        }
        .project-type{
          font-weight: 500;
          font-size: 22px;
          line-height: 41px;
          text-transform: capitalize;
          color: #000000;
          margin-left: 25px;
          @media(min-width: 992px) and (max-width: 1199px){
            font-size: 18px;
            line-height: 28px;
            margin-left: 15px;
          }
        }
      }
    }
    .clients-dashboard-monthly-list{
      margin-top: 50px;
      column-gap: 25px;
      @media(min-width: 992px) and (max-width: 1199px){
        column-gap: 15px;
        margin-top: 30px;
      }
      @media (max-width: 991px){
        flex-direction: column;
        row-gap: 25px;
      }
      .clients-dashboard-monthly{
        background: #FFFFFF;
        box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
        border-radius: 13px;
        height: 406px;
        padding: 15px 25px;
        // width: 650px;
        width: 100%;
        overflow-y: auto;
        position: relative;
        .ant-spin-spinning{
          position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .ant-spin-dot-item{
              background-color: #E5C243;
            }
        }
        &::-webkit-scrollbar {
          width: 0px;
          background: transparent;
        }
        @media(min-width: 992px) and (max-width: 1199px){
          height: 340px;
          width: 100%;
        }
        @media (min-width: 768px) and (max-width: 991px){
          width: 100%;
        }
        @media (max-width: 767px){
          width: 100%;
          height: auto;
        }
        .clients-dashboard-receivables{
          padding-bottom: 15px;
          border-bottom: 1px solid #0000001f;
          margin-bottom: 15px;
          .clients-project{
            font-weight: 500;
            font-size: 17px;
            line-height: 41px;
            text-transform: capitalize;
            color: #000000;
            position: relative;
            padding: 5px;
            @media(min-width: 992px) and (max-width: 1199px){
              padding: 2px;
              font-size: 15px;
            }
            &:before{
              content: '';
              position: absolute;
              border-radius: 50%;
              border: 2px solid #E5C243;
              width: 33px;
              height: 33px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .clients-project-text{
            font-weight: 500;
            font-size: 17px;
            line-height: 41px;
            text-transform: capitalize;
            color: #000000;
            margin-left: 15px;
            @media(min-width: 992px) and (max-width: 1199px){
              font-size: 14px;
              line-height: 14px;
            }
          }
        }
      }
      .client-project-list{
        padding-bottom: 20px;
        border-bottom: 1px solid #0000001f;
        margin-bottom: 20px;
        @media (max-width: 767px){
          flex-direction: column;
        }
        img{
          @media(min-width: 992px) and (max-width: 1199px){
            width: 25px;
          }
        }
        .clients-project-main{
          column-gap: 25px;
          @media (max-width: 768px){
            flex-direction: column;
          }
        }
        .clients-project-date{
          @media(max-width: 767px){
            text-align: center;
            margin-top: 10px;
          }
          .project-rs{
            font-weight: 500;
            font-size: 16px;
            text-transform: capitalize;
            color: #000000;
            @media(min-width: 992px) and (max-width: 1199px){
              font-size: 14px;
            }
            @media(max-width: 767px){
              margin-bottom: 10px;
            }
          }
          .project-date{
            font-weight: normal;
            font-size: 15px;
            text-transform: capitalize;
            color: rgba(0, 0, 0, 0.59);
            @media(min-width: 992px) and (max-width: 1199px){
              font-size: 10px;
            }
            @media(max-width: 767px){
              margin-top: 10px;
              margin-bottom: 10px;
            }
          }
        }
        .clients-project-name{
          font-weight: normal;
          font-size: 15px;
          text-transform: capitalize;
          color: rgba(0, 0, 0, 0.59);
          @media(min-width: 992px) and (max-width: 1199px){
            font-size: 10px;
          }
          @media(max-width: 767px){
            margin-bottom: 10px;
          }
        }
      }
      .client-agreements-list{
        padding-bottom: 20px;
        border-bottom: 1px solid #0000001f;
        margin-bottom: 20px;
        @media(max-width: 767px) {
          flex-direction: column;
        }
        .client-agreements-contact-wrapper{
          display: flex;
          align-items: center;
          column-gap: 15px;
          @media(max-width: 768px){
            flex-direction: column;
          }
          img{
            @media(min-width: 992px) and (max-width: 1199px){
              width: 25px;
            }
          }
          .clients-agreements-contact{
            @media (max-width: 767px){
              text-align: center;
              margin-top: 10px;
            }
            .agreements-contact{
              font-weight: 500;
              font-size: 16px;
              text-transform: capitalize;
              color: #000000;
              display: flow-root;
              width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              @media(min-width: 992px) and (max-width: 1199px){
                font-size: 14px;
              }
  
            }
            .agreements-date{
              font-weight: normal;
              font-size: 15px;
              text-transform: capitalize;
              color: rgba(0, 0, 0, 0.59);
              @media(min-width: 992px) and (max-width: 1199px){
                font-size: 10px;
              }
              @media(max-width: 767px){
                margin-top: 10px
                ;
                margin-bottom: 10px;
              }
            }
          }
        }
        
        .agreements-project-name{
          font-weight: normal;
          font-size: 15px;
          text-transform: capitalize;
          color: rgba(0, 0, 0, 0.59);
          
          @media(min-width: 992px) and (max-width: 1199px){
            font-size: 10px;
          }
          @media(max-width: 767px){
            margin-bottom: 10px;
          }
          @media(min-width: 767px){
            width: 170px;
          }
        }
        .agreements-project-project{
          background: #E5C243;
          border-radius: 34px;
          font-weight: 500;
          font-size: 11px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          padding: 2px 8px;
          border: none;
          @media(min-width: 992px) and (max-width: 1199px){
            font-size: 10px;
            line-height: 15px;
          }
          @media(max-width: 767px){
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}