.team-pagination{
  margin-bottom: 30px;
  @media (max-width: 991px){
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .ant-pagination{
    .ant-pagination-item{
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      font-size: 15px;
      color: #727272;
    }
    .ant-pagination-next{
      .ant-pagination-item-link{
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        font-size: 15px;
        color: #727272;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-pagination-prev{
      .ant-pagination-item-link{
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        font-size: 15px;
        color: #727272;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ant-pagination-item-active{
      background: #E5C243;
      color: #000;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      border:none;
      font-size: 15px;
    }
  }
}
.team-tabs-main {
  .tabs-wrapper{
    margin: 0 auto;
    @media (max-width: 991px){
      padding: 0;
      width: 100%;
    }
    .blog-section{
      @media(max-width: 991px){
        margin: 50px 0 33px;
        row-gap: 35px;
      }
      .blog-icon-wrapper{
        .blog-icon-text{
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-transform: capitalize;
          color: #000000;
          padding-left: 15px;
        }
      }
      .blog-input-warpper{
        position: relative;
        @media(max-width: 991px){
          width: 55%;
          height: 100%;
        }
        .blog-input-img{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 5%;

        }
        .blog-input{
          border: 1px solid #000000;
          border-radius: 36px;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          padding: 8px 25px 8px 39px;
          &::placeholder{
            color: #162642;
          }
          @media(max-width: 991px){
            width: 100%;
          }
        }
      }
      .blog-button{
        background: #E5C243;
        border-radius: 34px;
        font-weight: 500;
        font-size: 15px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        margin-left: 15px;
        border: none;
        padding:  6px 14px;

        .blog-button-img{
          padding-right: 10px;
        }
      }
    }
  }
}
.team-desktop-wrap{
  display: flex;
  row-gap: 15px;
  flex-direction: column;
  .investor-spin{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    .ant-spin-dot-item{
      background-color: #E5C243;
    }
  }
  .main-table-wrapper {
    .table-header {
      background: #000000;
      border-radius: 14px;
      height: 53px;
      color: #FFFFFF;
      margin: 40px 0;
      padding: 0 22px;
      @media(min-width: 991px) and (max-width: 1199px){
        padding: 0 12px;
      }
      .th-data {
        font-size: 15px;
        line-height: 24px;
        cursor: pointer;
        color: #fff;
        @media (min-width: 992px) and (max-width: 1199px){
          font-size: 14px;
        }
        &:first-child{
          flex: 0.25 1;
        }
        .img-icon {
          height: 8px;
          margin-left: 8px;
        }
        &:first-child {
          display: flex;
          justify-content: flex-start;
        }
        flex: 1;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
  .table-body {
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    height: 60px;
    margin-bottom: 30px;
    cursor: pointer;
    padding: 0 22px;
    @media (min-width: 992px) and (max-width: 1199px){
      padding: 0 12px;
    }
    @media(max-width: 991px){
      height: auto;
      flex-direction: column;
      padding: 22px;
    }

    .table-data {
      display: flex;
      align-items: center;
      flex: 1;
      &:first-child{
        flex: 0.2 ;
        @media(max-width: 991px){
          width: 100%;
        }
      }

      .blog-card{
        @media(max-width: 991px){
          width: 300px;
          justify-content: unset;
          margin-bottom: 20px;
        }
        @media (max-width: 320px){
          width: 200px;
        }
        .table-data-label{
          @media(max-width: 991px){
            width: 160px;
          }
        }
        .project{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background: #E5C243;
          display: flex;
          align-items: center;
          justify-content: center;
        ;
        }
        .technology{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .job{
          font-weight: 500;
          font-size: 17px;
          line-height: 27px;
          text-transform: capitalize;
          color: #000000;
        }
      }

      .blog-button{
        background: rgba(107, 209, 0, 0.34);
        border-radius: 34px;
        font-weight: 500;
        font-size: 12px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #53A200;
        padding: 0 11px;
        border: none;
        @media (max-width: 991px){
          margin-bottom: 25px;
        }
      }
      .blog-button-draft{
        background: rgba(242, 72, 34, 0.34);
        border-radius: 34px;
        font-weight: 500;
        font-size: 12px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #F24822;
        padding: 0 11px;
        border: none;
      }
      .action-img{
        width: 39px;
        margin: 0 5px;
      }
      .username {
        column-gap: 15px;
        
        .user-icon{
          background: #E5C243;
          width: 45px;
          height: 45px;
          border-radius: 50%;
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          color: #162642;
          margin-bottom: 0;
        }
        .user-name{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
          margin-bottom: 0;
        }
        .blog-img{
          width: 38px;
          margin-right: 15px;
          @media(min-width: 992px) and (max-width: 1199px){
            width: 33px;
            margin-right: 5px;
          }
          @media (max-width: 991px){
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
    }
    .blog-action{
      @media (max-width: 991px){
        margin-top: 10px;
      }
    }
  }
}
.team-dashboard-wrapper{

  .ant-tabs-nav{
    margin: 35px 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    padding: 0 35px;
    .ant-tabs-nav-wrap{
      height: 80px;
      .ant-tabs-tab{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #24334E;
      }
      .ant-tabs-tab-active{
        font-size: 18px;
        line-height: 24px;

        .ant-tabs-tab-btn{
          font-weight: 500;
          color: #162642;
        }
      }
      .ant-tabs-ink-bar{
        background: #E5C243;
        height: 4px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px){
    .ant-tabs-nav{
      width: 100%;
      flex-direction: column-reverse;
      .ant-tabs-nav-wrap{
        justify-content: center;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px){
    .ant-tabs-nav{
      width: 100%;
    }
  }
  @media (max-width: 767px){
    .ant-tabs-nav{
      height: 70px;
      width: 100%;
      background: #FFFFFF;
      border-top: 0.4px solid #8D8D8D;
      border-bottom: 0.4px solid #8D8D8D;
      border-radius: 5px;
      overflow-y: hidden;
      overflow-x: auto;
      position: relative;
      .ant-tabs-nav-wrap{
        overflow: unset;
        height: 69px;
        display: flex;
        justify-content: center;

      }
    }
  }
}
.team-dashboard-section {
  display: flex;
  align-items: center;

  .team-dashboard-button{
    display: flex;
    .team-btn {
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 36px;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      color: #162642;
      background: #fff;
      margin: 0 6px;
    }
  }
  .team-input-wrapper{
    position: relative;
    margin-left: 6px;
    @media(max-width: 991px){
      width: 55%;
      height: 100%;
    }
    .team-input-img{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 5%;
    }
    .team-input{
      border: 1px solid #000000;
      border-radius: 36px;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      padding: 8px 15px 8px 35px;
      &::placeholder{
        color: #162642;
      }
      @media(max-width: 991px){
        width: 100%;
      }
    }
  }
  .slider-wrap {
    width: 100%;
    .ant-slider {
      margin: 0 !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px){
    padding-top: 25px;
  }
  @media (max-width: 767px){
    padding: 30px 15px;
    row-gap: 25px;
  }
}
.team-filter-main{
  position: relative;
  .team-filter-wrapper{

    .btn-secondary{
      background: unset;
      border: unset;
      img{
        position: absolute;
        bottom: 0px;
        right: 15px;
        width: 100px;
      }
    }
  }
  .team-filter-menu{
    width: 100%;
    padding: 45px 30px;
    background: #FFFFFF;
    position: absolute;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.19);
    border-radius: 13px;
    border: unset;
    transform: translate3d(-5px, -260px, -16px) !important;
    .team-filter-cancel{
      position: relative;
      margin-bottom: 26px;
      .team-filter-text{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
      .team-cancel{
        position: absolute;
        top: -85%;
        right: -6%;
      }
    }

    .team-dashboard-button{
      display: flex;
      flex-wrap: wrap;
      .team-btn {
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 36px;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        color: #162642;
        background: #fff;
        margin: 0 6px 27px;
        height: 40px;
        text-align: left;
      }
    }
    .team-input-wrapper{
      position: relative;
      margin-left: 6px;
      @media(max-width: 991px){
        height: 100%;
      }
      .team-input-img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5%;
      }
      .team-input{
        border: 1px solid #000000;
        border-radius: 36px;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        padding: 8px 15px 8px 45px;
        &::placeholder{
          color: #162642 !important;
        }
        @media(max-width: 991px){
          width: 100%;
        }
      }
    }
    .team-apply-btn{
      background: #E5C243;
      border-radius: 53px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: capitalize;
      color: #000000;
      margin-top: 34px;
      width: 100%;
      border: unset;
      &:focus{
        border: unset;
        box-shadow: unset;
      }
    }
  }
}
.ant-tooltip{
  .ant-tooltip-inner{
    background: #000000;
    box-shadow: 0px 0px 11px rgba(32, 14, 50, 0.18);
    border-radius: 7px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 12px;
  }
}
.removeSpanCss {
  height: inherit !important;
  width: inherit !important;
  background: none !important;
  border-radius: 0 !important;
  font-size: 16px !important;
  color: #000000 !important;
  opacity: 1 !important;
  display: initial !important;
}