.add-agreement-wrapper {
  width: 100%;
  @media (max-width: 768px) {
    padding: 0 25px;
    width: 100%;
    background: #fff;
  }
  .header-left {
    margin-bottom: 25px;
    @media (max-width: 991px) {
      margin: 25px 15px;
    }
    @media(max-width: 375px){
      flex-direction: column;
      row-gap: 15px;
      align-items: flex-start !important;
    }
    .back-button {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #000000;
      span {
        margin-right: 5px;
      }
    }
    .agreements-templates{
      .agreements-templates-text{
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        margin-right: 10px;
        @media (max-width: 425px){
          font-size: 15px;
        }
      }
      .agreements-templates-sow{
        font-weight: 500;
        font-size: 17px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        border: 2px solid #E5C243;
        box-sizing: border-box;
        border-radius: 34px;
        padding: 5px 20px;
        margin-right: 10px;
        @media (max-width: 425px){
          font-size: 15px;
        }
      }
      .agreements-templates-msa{
        font-weight: 500;
        font-size: 17px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        border: 2px solid #E5C243;
        box-sizing: border-box;
        border-radius: 34px;
        padding: 5px 20px;
        @media (max-width: 425px){
          font-size: 15px;
        }
      }
    }
  }
  .add-agreement-main {
    background: #ffffff;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    padding: 100px;
    margin-bottom: 50px;
    @media (min-width: 992px) and (max-width: 1199px) {
      padding: 50px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding: 50px;
    }
    @media (max-width: 767px){
      padding: 80px 40px;
    }
    @media (max-width: 320px){
      padding: 50px 25px;
    }
    .agreement-head-wrapper {
      margin-bottom: 55px;
      @media (max-width: 767px){
        margin-bottom: 40px;
      }
      .agreement-head {
        font-weight: 500;
        font-size: 27px;
        line-height: 49px;
        text-align: center;
        color: #000000;
        @media (max-width: 767px){
          font-size: 24px;
        }
      }
    }
    .add-agreement-card-wrapper {
      @media (max-width: 767px){
        row-gap: 45px;
      }
      .agreement-editor-wrapper {
        cursor: pointer;
        background: #ffffff;
        border: 6px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        border-radius: 28px;
        padding: 30px 60px;
        @media (min-width: 992px) and (max-width: 1199px) {
          padding: 30px 25px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          padding: 20px 40px;
        }
        @media (max-width: 767px){
          padding: 20px 40px;
          width: 100%;
        }
        @media (max-width: 320px){
          padding: 15px 25px;
          width: 100%;
        }
        img{
          @media(max-width: 767px){
            width: 40px;
          }
        }
        .agreement-editor-text {
          font-weight: 500;
          font-size: 22px;
          line-height: 29px;
          text-transform: capitalize;
          color: #000000;
          margin-left: 20px;
          @media (max-width: 767px){
            font-size: 20px;
            margin-left: 15px;
          }
        }
      }
      .agreement-upload-wrapper {
        cursor: pointer;
        background: #ffffff;
        border: 6px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        border-radius: 28px;
        padding: 30px 60px;
        @media (min-width: 992px) and (max-width: 1199px) {
          padding: 30px 25px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          padding: 20px 40px;
        }
        @media (max-width: 767px){
          padding: 20px 40px;
          width: 100%;
        }
        @media (max-width: 320px){
          padding: 15px 25px;
          width: 100%;
        }
        img{
          @media(max-width: 767px){
            width: 50px;
          }
        }
        .agreement-upload-text {
          font-weight: 500;
          font-size: 22px;
          line-height: 29px;
          text-transform: capitalize;
          color: #000000;
          margin-left: 20px;
          @media (max-width: 767px){
            font-size: 20px;
            margin-left: 15px;
          }
        }
      }
      .active {
        cursor: pointer;
        border: 6px solid #e5c243;
      }
    }
    .add-agreement-button {
      margin-top: 80px;
      @media (max-width: 767px){
        margin-top: 50px;
      }
      .agreement-button {
        background: #e5c243;
        border-radius: 53px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        padding: 13px 30px;
        border: unset;
        
      }
    }
  }
}
