.perk-view-main-wrapper{
  
  @media (max-width: 768px){
    padding: 50px 25px;
    width: 100%;
  }
  .header-left{
    margin-bottom: 25px;
    @media(max-width: 991px){
      margin: 25px 15px;
    }
    
    @media(max-width: 375px){
      flex-direction: column;
      row-gap: 15px;
      align-items: flex-start !important;
    }
    .back-button{
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #000000;
      span{
        margin-right: 5px;
      }
    }
  }
  .perk-card-main{
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 0 0 13px 13px;
    padding: 30px 50px;
    margin-bottom: 50px;
    @media (min-width: 768px) and (max-width: 991px){
      padding: 50px 30px;
    }
    @media(max-width: 767px){
      padding: 30px 20px;
    }
    .perk-title-wrapper{
      color: #000;
      border-radius: 13px;
      margin-bottom: 40px;
      @media( max-width: 767px){
        //padding: 22px 20px 0;
        margin-bottom: 30px;
      }
      .perk-profile{
        margin-bottom: 20px;
        .profile-img-wrapper{
          padding: 40px 60px 40px 40px;
          .img-fluid {
            width: 180px;
          }
          @media (max-width: 991px){
            padding: 20px 40px 20px 20px ;
          }
        }
        .profile-details{
          .user-name{
            font-weight: 500;
            font-size: 28px;
            line-height: 41px;
            color: #000000;
            margin-bottom: 5px;
            @media (max-width: 991px){
              font-size: 24px;
              line-height: 34px;
            }
            @media (max-width: 767px){
              text-align: center;
            }
          }
          .profile-text{
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            color: #000000;
            margin-bottom: 20px;
            @media (max-width: 991px){
              font-size: 16px;
              line-height: 26px;
              margin-bottom: 15px;
            }
            @media (max-width: 767px){
              text-align: center;
            }
          }
          .type-website-wrapper{
            column-gap: 100px;
            @media (max-width: 767px){
              row-gap: 25px;
            }
            .profile-label{
              font-weight: normal;
              font-size: 17px;
              line-height: 34px;
              color: #000000;
              opacity: 0.53;
              @media(max-width: 991px){
                font-size: 14px;
                line-height: 34px;
              }
            }
            .type-wrapper{
              .value{
                background: rgba(145, 215, 71, 0.34);
                border-radius: 22px;
                font-weight: 500;
                font-size: 14px;
                line-height: 31px;
                text-align: center;
                color: #529E02;
                padding: 0 12px;
                @media(max-width: 991px){
                  font-size: 12px;
                  line-height: 26px;
                }
              }
            }
            .website-wrapper{
              .website{
                font-weight: normal;
                font-size: 18px;
                line-height: 34px;
                color: #000000;
                @media(max-width: 991px){
                  font-size: 16px;
                  line-height: 26px;
                }
              }
            }
          }

        }
      }
      .perk-profile-text{
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }
    }
    .add-perk-card{
      border-top: 1px solid #D3D3D3;
      padding-top: 35px;
      @media(max-width: 767px){
        padding: 30px 0 0;
      }
      .perk-replay{
        font-weight: 500;
        font-size: 24px;
        line-height: 41px;
        color: #000000;
        @media (max-width: 991px) {
          font-size: 20px;
        }
      }
      .replay-text{
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        margin-top: 15px;
        @media (max-width: 767px){
          text-align: center;
        }
      }
    }

  }
}