.update-detail-wrapper{
  .update-detail-main{
    @media (min-width: 768px) and (max-width: 991px){
      width: 100%;
      padding: 0;
    }
    @media( max-width: 767px){
      padding: 0;
      width: 100%;
      background: #fff;
    }
    .header-left{
      margin-bottom: 25px;
      @media(max-width: 991px){
        margin: 25px 15px;
        padding: 0 30px;
      }
      @media(max-width: 375px){
        flex-direction: column;
        row-gap: 15px;
        align-items: flex-start !important;
      }
      .back-button{
        cursor: pointer;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-transform: capitalize;
        color: #000000;
        span{
          margin-right: 5px;
        }
      }
    }
    .update-main-wrapper{
      @media(min-width: 768px) and (max-width: 991px){
        padding: 0 30px;
      }
      @media(max-width: 767px){
        padding: 0 15px;
      }
      .update-title-wrapper{
        //background: #000;
        color: #000;
        padding: 0px 33px;
        border-radius: 13px 13px 0 0;
        @media( max-width: 767px){
          padding: 22px 20px;
          margin: 0 15px;
        }
        .update-title{
          font-weight: 500;
          font-size: 27px;
          line-height: 41px;
          color: #000000;
          margin-bottom: 0;
          @media (max-width: 767px){
            font-size: 18px;
            line-height: 24px;
          }
        }
        .update-title-date{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
          margin-bottom: 5px;
          @media (max-width: 767px){
            font-size: 12px;
            line-height: 22px;
            margin-top: 5px;
          }
        }
        .update-title-sent{
          background: rgba(107, 209, 0, 0.34);
          border-radius: 34px;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #53A200;
          padding: 1px 20px;
        }
        .update-title-draft{
          background: rgba(242, 72, 34, 0.34);
          border-radius: 34px;
          font-weight: 500;
          font-size: 12px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #f24822;
          padding: 1px 20px;
        }
      }
      .update-card-main{
        background: #FFFFFF;
        box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
        border-radius: 0 0 13px 13px;
        padding: 50px 0 0;
        margin-bottom: 50px;
        @media(max-width: 767px){
          padding: 10px 0 0;
          margin: 0 15px 50px;
        }
        .add-update-card{
          padding: 20px 50px 80px;
          @media (min-width: 768px) and (max-width: 991px){
            padding: 20px 30px 80px;
          }
          @media(max-width: 767px){
            padding: 20px 20px 50px;
          }
          .add-update-text{
            font-weight: normal;
            font-size: 18px;
            line-height: 34px;
            color: #000000;
          }
          .link-wrapper{
            .links{
              font-weight: normal;
              font-size: 18px;
              line-height: 41px;
              color: #3372EC;
            }
          }
        }
      }
    }
  }
}