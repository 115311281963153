.client-feedback-main {
  @media (max-width: 991px){
    padding: 0;
  }
  .client-feedback-desktop-wrap{
    display: block;
    position: relative;
    .client-feedback-spin{
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      .ant-spin-dot-item{
        background-color: #E5C243;
      }
    }
    .total-credits{
      font-weight: normal;
      font-size: 17px;
      line-height: 24px;
      color: #162642;
      @media (min-width: 768px) and (max-width: 991px){
        margin-bottom: 20px;
      }
      @media (max-width: 767px){
        margin-bottom: 20px;
      }
    }
    .main-client-wrapper {
      .table-header {
        background: #000000;
        border-radius: 14px;
        height: 53px;
        color: #FFFFFF;
        margin: 40px 0;
        padding: 0 22px;
        @media(min-width: 991px) and (max-width: 1199px){
          padding: 0 12px;
        }
        .th-data {
          font-size: 15px;
          line-height: 24px;
          cursor: pointer;
          color: #fff;
          @media (min-width: 992px) and (max-width: 1199px){
            font-size: 14px;
          }

          .img-icon {
            height: 8px;
            margin-left: 8px;
          }
          &:first-child {
            display: flex;
            justify-content: flex-start;
          }
          flex: 1;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
    .table-body {
      background: #FFFFFF;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 13px;
      height: 60px;
      margin-bottom: 30px;
      cursor: pointer;
      padding: 0 22px;
      @media (min-width: 992px) and (max-width: 1199px){
        padding: 0 12px;
      }
      @media(max-width: 991px){
        height: auto;
        flex-direction: column;
        padding: 22px;
      }

      .table-data {
        display: flex;
        align-items: center;
        flex: 1;

        width: 100%;
        @media (max-width: 991px){
          justify-content: center;
        }
        .feedback-card{
          @media(max-width: 991px){
            width: 100%;
            display: flex;
            justify-content: space-around;
            margin-bottom: 20px;
          }
          @media (max-width: 320px){
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .table-data-label{
            @media(max-width: 991px){
              width: 120px;
            }
            @media (max-width: 320px){
              width: unset;
            }
          }
          .submission-date{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
          }
          .replay-button-group{
            .replay-button{
              background: #E5C243;
              border-radius: 34px;
              font-weight: 500;
              font-size: 15px;
              line-height: 27px;
              text-align: center;
              text-transform: capitalize;
              color: #000000;
              padding: 6px 15px;
              border: unset;
            }
            .replay-text-icon{
              width: 20px;
              height: 20px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #53A100;
              color: #fff;
              font-size: 10px;
              margin-right: 5px;
            }
            .replay-text{
              font-weight: 500;
              font-size: 15px;
              line-height: 24px;
              color: #53A100;
            }
          }
        }

        .username {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
          @media(max-width: 991px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;
          }
          .feedback-img{
            width: 48px;
            margin-right: 15px;
            @media(min-width: 992px) and (max-width: 1199px){
              width: 33px;
              margin-right: 5px;
            }
            @media (max-width: 991px){
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  .client-feedback-pagination{
    margin: 50px 0 80px;
    .ant-pagination{
      .ant-pagination-item{
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        font-size: 15px;
        color: #727272;
      }
      .ant-pagination-next{
        .ant-pagination-item-link{
          background: #FFFFFF;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          font-size: 15px;
          color: #727272;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ant-pagination-prev{
        .ant-pagination-item-link{
          background: #FFFFFF;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          font-size: 15px;
          color: #727272;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ant-pagination-item-active{
        background: #E5C243;
        color: #000;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        border:none;
        font-size: 15px;
      }
    }
  }
}