.profile-view-main-wrapper{
  
  @media (max-width: 991px){
    padding: 50px 25px;
    width: 100%;
  }
  .profile-header {
    display: flex;
    align-items: center;
    padding: 34px 0;
    h6 {
      margin: 0 0 0 10px;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;

    }
  }
  .profile-card-main{
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 0 0 13px 13px;
    padding: 50px;
    margin-bottom: 50px;
    @media (min-width: 768px) and (max-width: 991px){
      padding: 50px 30px;
    }
    @media(max-width: 767px){
      padding: 30px 20px;
    }
    .profile-title-wrapper{
      color: #000;
      border-radius: 13px;
      margin-bottom: 40px;
      @media( max-width: 767px){
        //padding: 22px 20px 0;
        margin-bottom: 30px;
      }
      .custom-supp, .custom-title {
        h6 {
          font-size: 16px;
          line-height: 26px;
          margin-right: 6px;
          margin-bottom: 0;
          font-weight: 300;
        }
        p {
          font-weight: 600;
          font-size: 17px;
          line-height: 26px;
        }
      }
      .main-location {
        .form-group-location {
          display: flex;
          align-items: center;
          position: relative;
          .location-img {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
          .location-input {
            border: 2px solid #D4D4D4;
            box-sizing: border-box;
            border-radius: 38px;
            padding: 3px 16px 3px 40px;
            outline: none;
            &:focus-visible, &:focus {
              outline: none;
              box-shadow: none;
            }
          }
          .location-search {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .location-wrapper {
        margin-top: 25px;
        .location-page {
          column-gap: 30px;
          p {
            font-weight: 600;
            font-size: 17px;
            line-height: 26px;
          }
          .location-wrap{
            width: 195px;
            .title {
              display: flex;
              align-items: center;

              h5 {
                font-size: 16px;
                line-height: 26px;
                margin-right: 6px;
                font-weight: 300;
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .project-permission-wrapper{
        column-gap: 30px;
      }
      .feedback-buttons {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        .button-save {
          background: #E5C243;
          border-radius: 53px;
          margin-right: 16px;
          font-weight: 500;
          font-size: 16px;
          color: #000;
          width: 95px;
          height: 34px;
          padding: 0;
          line-height: 34px;
          border: none;
        }
        .button-cancel {
          border: 2px solid #E5C243;
          box-sizing: border-box;
          border-radius: 53px;
          font-weight: 500;
          width: 95px;
          height: 34px;
          color: #000;
          padding: 0;
          background: transparent;
          font-size: 16px;
          line-height:30px;
        }
      }
      .profile-info-wrapper {
        padding: 0 40px 0 50px;
        border-left: 1px solid #e1e1e1;
        width: 100%;
        @media screen and (max-width: 767px) {
          border: none;
          padding: 0 24px;
        }
        .input-fields {

          .feedback-label {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 6px;
            margin-right: 6px;
          }
          .feedback-textarea {
            border: 2px solid #D4D4D4;
            box-sizing: border-box;
            border-radius: 29px;
            padding: 16px 30px;
          }
          p {
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 0;
            word-break: break-all;
          }
        }

    }
      .perk-profile{
        margin-bottom: 20px;
        .profile-img-wrapper{
          padding: 40px 60px 40px 40px;
          .edit-lead-profile {
            display: flex;
            justify-content: center;
            background: #FFFFFF;
            box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.26);
            border-radius: 50%;
            width: 150px;
            height: 150px;
            padding: 10px;
            position: relative;
            .ant-upload-list {
              display: none;
            }
            .cameraIcon {
              position: absolute;
              bottom: 0;
              right: 0;
              cursor: pointer;
            }
            .ant-avatar.ant-avatar-icon {
              width: 100%;
              font-size: 68px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .user-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            h4 {
              font-weight: bold;
              font-size: 24px;
              line-height: normal;
              margin: 12px 0 6px;
              text-align: center;
            }
            span {
              font-weight: 500;
              font-size: 17px;
              line-height: 26px;
            }
          }
          @media (max-width: 991px){
            padding: 20px 40px 20px 20px ;
          }
        }
        .profile-details{
          .user-name{
            font-weight: 500;
            font-size: 28px;
            line-height: 41px;
            color: #000000;
            margin-bottom: 5px;
            @media (max-width: 991px){
              font-size: 24px;
              line-height: 34px;
            }
            @media (max-width: 767px){
              text-align: center;
            }
          }
          .profile-text{
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            color: #000000;
            margin-bottom: 20px;
            @media (max-width: 991px){
              font-size: 16px;
              line-height: 26px;
              margin-bottom: 15px;
            }
            @media (max-width: 767px){
              text-align: center;
            }
          }
          .type-website-wrapper{
            column-gap: 100px;
            @media (max-width: 767px){
              row-gap: 25px;
            }
            .profile-label{
              font-weight: normal;
              font-size: 17px;
              line-height: 34px;
              color: #000000;
              opacity: 0.53;
              @media(max-width: 991px){
                font-size: 14px;
                line-height: 34px;
              }
            }
            .type-wrapper{
              .value{
                background: rgba(145, 215, 71, 0.34);
                border-radius: 22px;
                font-weight: 500;
                font-size: 14px;
                line-height: 31px;
                text-align: center;
                color: #529E02;
                padding: 0 12px;
                @media(max-width: 991px){
                  font-size: 12px;
                  line-height: 26px;
                }
              }
            }
            .website-wrapper{
              .website{
                font-weight: normal;
                font-size: 18px;
                line-height: 34px;
                color: #000000;
                @media(max-width: 991px){
                  font-size: 16px;
                  line-height: 26px;
                }
              }
            }
          }

        }
      }
      .perk-profile-text{
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }
    }
    .add-perk-card{
      border-top: 1px solid #D3D3D3;
      padding-top: 35px;
      @media(max-width: 767px){
        padding: 30px 0 0;
      }
      .perk-replay{
        font-weight: 500;
        font-size: 24px;
        line-height: 41px;
        color: #000000;
        @media (max-width: 991px) {
          font-size: 20px;
        }
      }
      .replay-text{
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        margin-top: 15px;
        @media (max-width: 767px){
          text-align: center;
        }
      }
    }

  }
}