.yourself-main{
  padding: 0 25px;
  .yourself-wrapper{
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    width: 649px;
    margin: 0 auto;
    margin-bottom: 50px;
    @media (min-width: 768px) and (max-width: 991px){
      width:100%;
      margin: 50px 0;
    }
    @media(max-width: 767px){
      width: 100%;
      margin: 50px 0;
    }
    .yourself-head{
      background: #000;
      width: 100%;
      padding: 25px ;
      border-radius: 13px 13px 0px 0px;
      .yourself-items-img{
        width: 30px;
        padding-right: 11px;
      }
      .yourself-head-text{
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        @media(max-width: 767px){
          font-size: 16px;
        }
      }
    }
    .yourself-profile{
      margin-top: 38px;
    }
    .yourself-formgroup{
      width: 100%;
      padding: 0 70px;
      @media(max-width: 767px){
        padding: 0 30px;
      }
      .yourself-lable{
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
      }
      .form-group-location{
        position: relative;
        margin-bottom: 35px;
        .location-img{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 15px;
        }
        .location-input{
          border: 2px solid #D4D4D4;
          border-radius: 38px;
          padding: 0 45px;
          &::placeholder{
            color: #787878;
          }
        }
        .location-search{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 15px;
        }
      }
      .yourself-textarea{
        border-radius: 29px;
        border: 2px solid #D4D4D4;
        height: 139px;
      }
      .yourself-button{
        background: #E5C243;
        border-radius: 48px;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        color: #000000;
        padding: 18px 49px;
        border: none;
        margin: 50px 0;
        @media(max-width: 767px){
          padding: 10px 25px;
          margin: 30px 0 50px;
          font-size: 20px;
        }
        .yourself-button-img{
          padding-left: 11px;
          @media(max-width: 767px){
            width: 38px;
          }
        }
      }
    }
  }
}
