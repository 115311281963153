.invite-succeess-main{
    .invite-succeess-wrapper{
        height: calc(100vh - 200px);
        @media (max-width:767px){
            height: calc(100vh - 300px);
            margin: 70px 0;
        }
        .invite-succeess-text{
            font-weight: 500;
            font-size: 24px;
            line-height: 41px;
            text-align: center;
            color: #000000;
            margin-top: 25px;
        }
    }
}