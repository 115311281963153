.edit-lead-main{
  width: 100%;
  @media (max-width: 767px){
    padding: 0 15px;
  }
  .edit-lead-headding{
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000000;
    margin: 35px 0 30px;
    display: flex;
    @media(max-width: 767px){
      margin: 30px 0 20px;
    }
  }
  .edit-lead-wrapper{
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    margin-bottom: 50px;
    @media (min-width: 768px) and (max-width: 991px){
      width:100%;
      margin: 50px 0;
    }
    @media(max-width: 767px){
      width: 100%;
      margin: 30px 0;
    }

    .edit-lead-profile{
      margin-top: 38px;
      img {
        width: 100px;
        border-radius: 50%;
      }
    }
    .edit-lead-type{
      //min-width: 270px;
      margin: 35px 0;
      .edit-lead-toggle-wrapper {
        //width: 285px;
        @media(max-width: 767px){
          width: 100%;
        }
        .custom-toggle-button{
          padding-left: 0;
          height: 40px;
          border: 2px solid #D4D4D4;
          box-sizing: border-box;
          border-radius: 20px !important;
          .btn-default {
            padding: 0 12px;
            margin-bottom: 0;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            align-items: center;
            display: flex;
            @media(max-width: 320px){
              font-size: 14px;
            }
          }
          .active{
            background: #53A100;
            color: #fff;
            border-radius: 20px;
            border: 1px solid transparent;
            padding: 0 12px;
            font-weight: 500;
            align-items: center;
            display: flex;
            margin: -1px -1px;
          }
        }
      }
    }
    .edit-lead-formgroup{
      width: 100%;
      padding: 0 70px;
      margin-top: 20px;
      @media(max-width: 767px){
        padding: 0 30px;
      }

      .name-wrapper{
        column-gap: 15px;
        @media(max-width: 767px){
          flex-direction: column;
          row-gap: 10px;
        }
      }
      .edit-lead-lable{
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
      }
      .edit-lead-input{
        background: #FFFFFF;
        border: 2px solid #D4D4D4;
        box-sizing: border-box;
        border-radius: 38px;
        padding: 7px 25px;
      }
      .form-group-location{
        position: relative;
        margin-bottom: 35px;
        .location-img{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 15px;
        }
        .location-input{
          border: 2px solid #D4D4D4;
          border-radius: 38px;
          padding: 0 45px;
          &::placeholder{
            color: #787878;
          }
        }
        .location-search{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 15px;
        }
      }
      .edit-lead-button{
        margin: 50px 0;
        .edit-lead-button-save{
          background: #E5C243;
          border-radius: 48px;
          font-weight: bold;
          font-size: 22px;
          line-height: 26px;
          color: #000000;
          padding: 12px 49px;
          border: none;

          @media(max-width: 767px){
            padding: 10px 25px;
            //margin: 30px 0 50px;
            font-size: 20px;
          }
        }
        .edit-lead-button-cencle{
          border: 2px solid #E5C243;
          box-sizing: border-box;
          border-radius: 53px;
          font-weight: bold;
          font-size: 22px;
          line-height: 26px;
          color: #000000;
          background: transparent;
          padding: 10px 49px;
          margin-left: 10px;
          @media(max-width: 768px){
            padding: 8px 25px;
            //margin: 30px 0 50px;
            font-size: 20px;
          }
        }
      }
    }
  }
}
