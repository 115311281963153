.agreement-main {
  @media (max-width: 991px){
    padding: 0;
  }
  .agreement-desktop-wrap{
    display: block;
    .client-invoice-spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .ant-spin-dot-item {
        background-color: #e5c243;
      }
    }
    .main-lead-wrapper {
      .table-header {
        background: #000000;
        border-radius: 14px;
        height: 53px;
        color: #FFFFFF;
        margin: 40px 0;
        padding: 0 22px;
        @media(min-width: 991px) and (max-width: 1199px){
          padding: 0 12px;
        }
        .th-data {
          font-size: 15px;
          line-height: 24px;
          cursor: pointer;
          color: #fff;
          @media (min-width: 992px) and (max-width: 1199px){
            font-size: 14px;
          }

          .img-icon {                                                            
            height: 8px;
            margin-left: 8px;
          }
          &:first-child {
            display: flex;
            justify-content: flex-start;
          }
          flex: 1;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
    .table-body {
      background: #FFFFFF;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
      border-radius: 13px;
      height: 60px;
      margin-bottom: 30px;
      cursor: pointer;
      padding: 0 22px;
      @media (min-width: 992px) and (max-width: 1199px){
        padding: 0 12px;
      }
      @media(max-width: 991px){
        height: auto;
        flex-direction: column;
        padding: 22px;
      }

      .table-data {
        display: flex;
        align-items: center;
        flex: 1;

        width: 100%;
        @media (max-width: 991px){
          justify-content: center;
        }
        .agreement-card{
          @media(max-width: 991px){
            width: 300px;
            justify-content: unset;
            margin-bottom: 20px;
          }
          @media (max-width: 320px){
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .table-data-label{
            @media(max-width: 991px){
              width: 120px;
            }
            @media (max-width: 320px){
              width: unset;
            }
          }
          .date{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
          }
          .amount{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
          }
          .incomplete{
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            color: #E5C243;
            .incomplete-img{
              margin-right: 8px;
            }
          }
          .status{
            .status-complete{
              font-weight: 500;
              font-size: 15px;
              line-height: 24px;
              color: #53A100;
            }
            .status-date{
              font-weight: 500;
              font-size: 12px;
              line-height: 27px;
              text-transform: capitalize;
              color: #000000;

            }
          }
          .download-icon{
            width: 40px;
            height: 40px;
            background: #E5C243;
            border-radius: 50%;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .reminder-buttons{
            background: #E5C243;
            border-radius: 34px;
            font-weight: 500;
            font-size: 15px;
            line-height: 27px;
            text-align: center;
            text-transform: capitalize;
            color: #000000;
            padding: 8px 25px;
            border: unset;
            margin-left: 10px;
            @media (max-width: 320px){
              margin-left: 0;
              margin-top: 10px;
            }
          }
        }

        .username {
          .agreements-username{
            font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 150px;
          }
          @media(max-width: 991px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;
          }
          .agreement-img{
            width: 48px;
            margin-right: 15px;
            @media(min-width: 992px) and (max-width: 1199px){
              width: 33px;
              margin-right: 5px;
            }
            @media (max-width: 991px){
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  .agreement-pagination{
    margin: 80px 0;
    .ant-pagination{
      .ant-pagination-item{
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        font-size: 15px;
        color: #727272;
      }
      .ant-pagination-next{
        .ant-pagination-item-link{
          background: #FFFFFF;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          font-size: 15px;
          color: #727272;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ant-pagination-prev{
        .ant-pagination-item-link{
          background: #FFFFFF;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          font-size: 15px;
          color: #727272;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ant-pagination-item-active{
        background: #E5C243;
        color: #000;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        border:none;
        font-size: 15px;
      }
    }
  }
}