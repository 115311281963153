.support-chat-main {
  margin-bottom: 50px;
  //padding: 35px 65px;
  //@media (max-width: 767px) {
  //  padding: 25px 20px;
  //}
  .support-setting-wrapper {
    @media (max-width: 767px) {
      row-gap: 25px;
    }
    .support-chat-wrapper {
      .support-tex {
        font-weight: 500;
        font-size: 23px;
        line-height: 24px;
        color: #000000;
        margin-left: 15px;
      }
    }
    .support-setting-button {
      background: #e5c243;
      border-radius: 53px;
      padding: 10px 25px;
      border: unset;
      .button-text {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        margin-left: 8px;
      }
    }
  }
  .support-chat-card {
    //background: #ffffff;
    //box-shadow: 0px 2px 19px rgba(197, 197, 197, 0.47);
    //border-radius: 13px;
    .support-card-header {
      background: #000000;
      border-radius: 13px 13px 0px 0px;
      margin-top: 27px;
      .support-header-main {
        padding: 13px 20px;
        .support-user-wrapper {
          margin-left: 15px;
          .support-user-name {
            font-weight: bold;
            font-size: 21px;
            line-height: 41px;
            text-transform: capitalize;
            color: #ffffff;
          }
          .support-user-chat {
            font-weight: normal;
            font-size: 11px;
            line-height: 13px;
            color: #ffffff;
          }
        }
      }
    }
    .support-card-body {
      //padding: 28px 58px;
      //@media (max-width: 767px) {
      //  padding: 20px 20px;
      //}
      .support-card-wrapper {
        height: 500px;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0px;
          background: transparent;
        }
        @media (max-width: 991px) {
          height: 400px;
        }
        .support-chat-date {
          .chat-date {
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            color: rgba(0, 0, 0, 0.7);
          }
        }
        .user-img {
          border: 1px solid #000000;
          border-radius: 50%;
        }
        .support-admin-chat {
          width: 100%;
          margin: 10px 0;
          .admin-chat-wrapper {
            background: rgba(0, 0, 0, 0.07);
            border-radius: 15px 15px 15px 0px;
            padding: 20px 25px;
            max-width: 45%;
            @media (min-width: 768px) and (max-width: 991px) {
              max-width: 85%;
            }
            @media (max-width: 767px) {
              max-width: 93%;
            }
            .admin-chat-message {
              margin-left: 20px;
              .admin-name {
                font-weight: bold;
                font-size: 14px;
                line-height: 150%;
                color: #000000;
                margin-bottom: 3px;
              }
              .admin-meassage {
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                color: #000000;
                word-break: break-all;
              }
            }
          }
          .admin-chat-date {
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.4);
          }
        }
        .support-user-chat {
          width: 100%;
          margin: 10px 0;
          .user-chat-wrapper {
            background: #000;
            border-radius: 15px 15px 0 15px;
            padding: 20px 25px;
            max-width: 45%;
            @media (min-width: 768px) and (max-width: 991px) {
             max-width: 85%;
            }
            @media (max-width: 767px) {
              max-width: 93%;
            }
            .user-chat-message {
              .user-meassage {
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                color: #fff;
                word-break: break-all;
              }
            }
          }
          .user-chat-date {
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.4);
          }
        }
        .support-features-chat {
          width: 100%;
          margin: 10px 0;
          color: white;
          .features-chat-wrapper {
            background: #e5c243;
            border-radius: 15px 15px 0px 15px;
            // padding: 29px 32px;
            width: 100%;
            .features-settings {
              background: #000000;
              border-radius: 14px 0px 69px;
              padding: 15px 32px;
              width: 288px;
              @media (max-width: 320px) {
                width: 90%;
              }
              .features-settings-icon {
                color: #fff;
                margin-right: 6px;
                font-size: 22px;
              }
              .features-settings-text {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                text-transform: capitalize;
                color: #ffffff;
              }
            }
            .features-chat-message {
              padding: 15px 32px 30px;
              .features-name {
                font-weight: 500;
                font-size: 18px;
                line-height: 41px;
                color: #000000;
                margin-bottom: 3px;
              }
              .features-meassage {
                font-weight: normal;
                font-size: 14px;
                line-height: 153%;
                color: #000000;
                word-break: break-all;
              }
            }
          }
          .features-chat-date {
            font-weight: normal;
            font-size: 12px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.4);
          }
        }
      }

      .support-card-meassage {
        padding: 30px 35px;
        background: #FFFFFF;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.13);
        border-radius: 13px;
        @media(max-width: 767px){
          padding: 30px 15px;
        }
        .support-meassage-wrapper {
          column-gap: 15px;
          .measssage-input {
            position: relative;
            width: 100%;
            .input {
              width: 100%;
              border: 2px solid #000000;
              box-sizing: border-box;
              border-radius: 15px;
              padding: 10px 50px;
            }
            .meassage-icon {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 20px;
            }
          }
          .chat-icons {
            background: #000000;
            border-radius: 15px;
            padding: 12px;
            .icons {
              color: #fff;
              font-size: 20px;
            }
          }
          .chat-files {
            background: #000000;
            border-radius: 15px;
            padding: 12px;
            .files {
              color: #fff;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
