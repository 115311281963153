.noDataFoundCon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        color: #000000;
        margin-bottom: 10px;
    }
    .normalText {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #000000;
    }
}