.feedback-view-main-wrapper{
  @media(min-width: 768px) and (max-width: 991px){
    padding: 0 30px;
  }
  @media(max-width: 767px){
    padding: 0 15px;
  }

  .feedback-card-main{
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 0 0 13px 13px;
    padding: 30px 50px;
    margin-bottom: 50px;
    @media(max-width: 767px){
      padding: 10px 0 0;
      margin: 0 15px 50px;
    }
    .feedback-title-wrapper{
      color: #000;
      border-radius: 13px;
      margin-bottom: 40px;
      @media( max-width: 767px){
        padding: 22px 20px 0;
        margin-bottom: 30px;
      }
      .feedback-profile{
        padding-bottom: 15px;
        border-bottom: 1px solid #D3D3D3;
        margin-bottom: 20px;
        .profile-details{
          margin-left: 15px;
          .user-name{
            font-weight: bold;
            font-size: 15px;
            text-align: center;
            text-transform: capitalize;
            color: #000000;
            margin-bottom: 5px;
          }
          .project-name{
            font-weight: normal;
            font-size: 14px;
            color: #000000;
            margin-bottom: 5px;
            .project{
              font-weight: 500;
            }
          }
          .date{
            font-weight: normal;
            font-size: 14px;
            color: #000000;
          }
        }
        .feedback-rate{
          margin-left: 50px;
          font-size: 24px;
        }
      }
      .feedback-profile-text{
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }
    }
    .add-feedback-card{
      border-top: 1px solid #D3D3D3;
      padding-top: 35px;
      @media(max-width: 767px){
        padding: 20px 20px 0;
      }
      .feedback-replay{
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        text-transform: capitalize;
        color: #000000;
      }
      .feedback-replay-date{
        font-weight: normal;
        font-size: 12px;
        line-height: 49px;
        color: #000000;
        .replay-date{
          font-weight: 600;
        }
      }
      .replay-text{
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
      }
    }

  }
}