.invoice-report-wrapper{
  margin-bottom: 50px;
  .invoice-report-card{
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    .invoice-report-header{
      background: #000000;
      border-radius: 13px 13px 0px 0px;
      padding: 24px 32px;
      .invoice-report-title{
        font-weight: bold;
        font-size: 22px;
        line-height: 24px;
        text-transform: capitalize;
        color: #FFFFFF;
        margin-bottom: 0;
      }
    }
    .invoice-report-body{
      padding: 50px 60px;
      .invoice-date-wrapper{
        .date-wrapper{
          .date-label{
            font-size: 14px;
            line-height: 24px;
            font-weight: normal;
            text-transform: capitalize;
            color: #6b6f77;
          }
          .date{
            font-size: 18px;
            line-height: 24px;
            font-weight: normal;
            color: #4f4f51;
            .due-date{
              color: #d675b6;
              margin-left:8px;
            }
          }
        }
      }
      .label{
        font-size: 14px;
        line-height: 24px;
        font-weight: normal;
        text-transform: capitalize;
        color: #6b6f77;
      }
      .bold-text{
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
      }
      .text{
        font-size: 18px;
        line-height: 24px;
        font-weight: normal;
        color: #4f4f51;
      }
      .table-thead{
        border-bottom: 1px solid #6b6f77;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
      .table-th{
        display: flex;
        flex: 0.3;
        &:first-child{
          flex: 1;
        }
      }
      .table-td{
        display: flex;
        flex: 0.3;
        &:first-child{
          flex: 1;
        }
      }
      .label-que{
        color: #686868;
      }
      .label-email{
        color: #9599c8;
      }
      .invoice-head{
        border-bottom: 1px solid #686868;
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
      }
      .invoice-label{
        font-size: 16px;
        line-height: 24px;
        color: #686868;
      }
      .invoice-text{
        font-size: 16px;
        line-height: 24px;
        color: #4f4f51;
      }
    }
  }
}