.lead-member-details-wrapper{
  width: 100%;
  
  .header-left{
    margin-bottom: 25px;
    @media(max-width: 991px){
      margin: 25px 15px;
    }
    .back-button{
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #000000;
      span{
        margin-right: 5px;
      }
    }
  }
  .lead-member-profile{
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    padding: 25px 50px;
    @media(min-width: 992px) and (max-width: 1199px){
      padding: 20px 25px;
    }
    @media (max-width: 991px){
      padding: 20px 20px;
    }
    @media(max-width: 768px){
      padding: 30px;
    }
    @media(max-width: 1067px){
      flex-direction: column;
      row-gap: 25px;
      
    }
    @media(max-width: 320px){
      padding: 35px 40px;
    }
    .user-name{
      font-size: 17px;
      line-height: 25px;
      text-align: center;
      text-transform: capitalize;
      color: #000000;
      img{
        cursor: pointer;
      }
    }
    .active-btn{
      background: rgba(107, 209, 0, 0.34);
      border-radius: 34px;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      text-transform: capitalize;
      color: #53A200;
      padding: 3px 12px;
      border: unset;
    }
    .lead-profile-card-wrapper{
      column-gap: 12px;
      @media(max-width: 768px){
        flex-direction: column;
        align-items: center;
        row-gap: 12px;
      }
      .lead-profile-card{
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        border-radius: 13px;
        width: 140px;
        //padding: 32px 54px;
        position: relative;
        @media(min-width: 992px) and (max-width: 1199px){
          width: 150px;
        }
        @media(min-width: 768px) and (max-width: 991px){
          width: 120px;
        }
        @media(max-width: 767px){
          width: 100%;
          height: 88px;
        }
        @media (max-width: 375px){
          height: unset;
          padding: 20px;
        }
        .lead-profile-card-text{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
          margin-top: 15px;
          @media (max-width: 767px){
            margin-top: 0;
            margin-left: 10px;
          }
          @media(max-width: 375px){
            font-size: 15px;
          }
        }
        .lead-reminder{
          position: absolute;
          bottom: 0;
          width: 100%;
          background: #E5C243;
          border-radius: 0 0 13px 13px;
          padding: 4px 0;
          span{
            font-weight: 500;
            font-size: 12px;
            //line-height: 27px;
            text-align: center;
            text-transform: capitalize;
            color: #000000;
          }
        }
      }
      .lead-profile-done{
        border: 1px solid #000;
      }
    }
  
  }
  .status-tab{
    .ant-tabs-nav{
      background: #FFFFFF;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      border-radius: 13px;
      margin: 35px 0 25px;
      padding: 0 40px;
      @media(min-width: 992px) and (max-width: 1199px){
        padding: 0 25px;
      }
      @media(max-width: 991px){
        padding:  0 20px;
      }
      @media (max-width: 768px){
        padding: 0 30px;
      }
      .ant-tabs-nav-wrap{
        height: 78px;
        .ant-tabs-tab{
          font-weight: 500;
          font-size: 17px;
          line-height: 24px;
          color: #24334E;
        }
        .ant-tabs-tab-active{
          font-weight: 600;

          .ant-tabs-tab-btn{
            font-size: 17px;
            line-height: 24px;
            font-weight: 500;
            color: #162642;
          }
        }
        .ant-tabs-ink-bar{
          background:  #E5C243;
          height: 4px;
        }
      }
    }
    @media (min-width: 992px) and (max-width: 1440px){
      .ant-tabs-nav{
        width: 100%;
      }
    }
    @media (min-width: 768px) and (max-width: 991px){
      .ant-tabs-nav{
        width: 100%;
      }
    }
    @media (max-width: 767px){
      .ant-tabs-nav{
        height: 70px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 5px;
        overflow-y: hidden;
        overflow-x: auto;
        position: relative;
        .ant-tabs-nav-wrap{
          overflow: auto;
          height: 69px;
          display: flex;
          //justify-content: center;

        }
      }
    }
    .ant-tabs-content-holder {
      @media (max-width: 768px) {
        padding: 0 30px;
      }
    }
  }
}
.invoice-button-wrapper{
  .invoice-button{
    background: #E5C243;
    border-radius: 34px;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    border: unset;
    padding: 6px 16px;
    .invoice-button-img{
      margin-right: 8px;
    }
    &:active,
    &:hover{
      background: #E5C243;
      background-color: #E5C243 !important;
      color: #000000 !important;
    }
    &:focus{
      box-shadow: unset;
    }
  }
}
.avatarMainCon {
  .user-icon {
    background: #E5C243;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    font-weight: 700;
    font-size: 35px;
    line-height: 24px;
    color: #162642;
    margin-bottom: 0;
    margin-bottom: 5px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.26);
  }
}