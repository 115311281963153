.project-details-wrapper{
  .project-email{
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    padding: 15px 0 40px;
    .project-email-edit{
      padding-right: 20px;
      cursor: pointer;
    }
    .project-email-detail{
      padding: 15px 60px 0;
      column-gap: 113px;
      @media(min-width: 992px) and (max-width: 1199px){
        padding: 15px 30px 0;
      }
      @media(max-width: 991px){
        padding: 15px 20px 0;
      }
      @media(max-width: 767px){
        padding: 50px;
        flex-direction: column;
        row-gap: 50px;
      }
      .email{
        padding-left: 20px;
        .email-label{
          font-weight: normal;
          font-size: 13px;
          line-height: 26px;
          text-transform: capitalize;
          color: #000000;
        }
        .email-text{
          font-weight: 500;
          font-size: 19px;
          line-height: 26px;
          text-transform: capitalize;
          color: #000000;
        }
      }
    }
  }
  .project-detail-main{
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    padding: 48px 60px;
    margin-top: 25px;
    margin-bottom: 25px;
    @media(min-width: 992px) and (max-width: 1199px){
      padding: 30px 25px;
    }
    @media(max-width: 991px){
      padding: 35px;
    }
    .project-detail{
      padding-bottom: 40px;
      border-bottom: 1px solid #E5C243;
      @media(max-width: 767px){
        flex-direction: column;
      }
      .details-project{
        margin-left: 20px;
        .project-edit{
          @media(max-width: 767px) {
            margin-top: 15px;
          }
        }
        @media(max-width: 767px){
          margin-left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
        .project-name{
          font-weight: 500;
          font-size: 28px;
          line-height: 41px;
          text-transform: capitalize;
          color: #000000;
          margin-right: 8px;
          @media(max-width: 767px){
            font-size: 20px;

          }
        }
        .project-text{
          font-weight: normal;
          font-size: 16px;
          line-height: 26px;
          color: #000000;
          @media(max-width: 767px) {
            margin-top: 15px;
          }
        }
      }
    }
    .project-desc{
      margin-top: 50px;
      .desc-main{
        @media (max-width: 767px){
          row-gap: 25px;
        }
      }
      .description{
        width: 200px;
        @media (max-width: 768px) {
          width: 100%;
        }
        .project-desc-label{
          font-weight: normal;
          font-size: 15px;
          line-height: 49px;
          color: #000000;
          img{
            cursor: pointer;
          }
          @media (max-width: 767px){
            line-height: 28px ;
            img{
              margin-left: 5px;
            }
          }
        }
        .project-desc-text{
          font-weight: 600;
          font-size: 17px;
          line-height: 24px;
          text-transform: capitalize;
          color: #000000;
        }
      }
      .features-documents-wrapper{
        column-gap: 120px;
        @media(min-width: 992px) and (max-width: 1199px){
          column-gap: 50px;
        }
        @media(max-width: 991px){
          column-gap: 0;
        }
        @media(max-width: 767px){
          flex-direction: column;
          row-gap: 25px;
        }
        .application{
          @media(min-width: 992px) and (max-width: 1199px){
            width: 350px;
          }
          @media(max-width: 991px){
            width: 100%;
          }
          .project-desc-label{
            font-weight: normal;
            font-size: 15px;
            line-height: 49px;
            color: #000000;
            @media (max-width: 767px){
              line-height: 28px ;
              img{
                margin-left: 5px;
              }
            }
          }
          .project-desc-text{
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            text-transform: capitalize;
            color: #000000;
          }
        }
        .documents{
          .project-desc-label{
            font-weight: normal;
            font-size: 16px;
            line-height: 49px;
            color: #000000;
            
          }
          .documents-text{
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            text-decoration-line: underline;
            color: #000000;
            margin: 0 10px;
          }
        }
      }

    }
  }
}
.projectDetailSelect {
  .ant-select-selector {
    border-radius: 15px !important;
  }
}
.projectMainWrapper {
  display: flex;
  align-items: center;
  .ant-input, .ant-input-number, .ant-picker {
    border-radius: 15px !important;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  img {
    margin-left: 7px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    margin-top: 10px;
  }
}
.uploadBoxProjectCon {
  border: 2px dashed #D4D4D4;
  box-sizing: border-box;
  border-radius: 27px;
  padding: 26px 50px;
  column-gap: 45px;
  .blog-upload-buttons button {
    background: #E5C243;
    border-radius: 38px;
    border: none;
    color: #000;
    font-weight: 500;
  }
}
.ant-upload-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ant-upload-select {
  display: flex !important;
    justify-content: center;
    align-items: center;
}
.themeSpinner {
  display: flex !important;
  margin-left: 10px !important;
  .ant-spin-dot-item {
    background-color: #E5C243 !important;
  }
}