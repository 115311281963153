.blog-detail-main-layout {
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px) !important;
  max-width: 1225px;
  padding: 0 24px;
  margin: 0 auto;
  .blog-detail-body {
    .investor-spin{
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      .ant-spin-dot-item{
        background-color: #E5C243;
      }
    }
    width: 100%;
    margin: 40px auto;
    padding: 50px 30px;
    background: #FFFFFF;
    box-shadow: 0 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    .mark-btn {
      background: #E5C243;
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      color: #000000;
      border: 1px solid transparent;
      border-radius: 25px;
      margin-right: 16px;
      &:hover {
        background: transparent;
        border: 1px solid #E5C243;
        color: #E5C243;
      }
    }
    .blog-detail-container {
     max-width: 760px;
     margin: 0 auto;
      .blog-content-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        h5 {
          color: #E5C243;
          font-size: 26px;
          line-height: 22px;
          text-transform: capitalize;
        }
        .body-header-text {
          font-weight: 500;
          font-size: 28px;
          line-height: 44px;
          text-transform: capitalize;
          color: #000000;
          position: relative;
          &:after {
            content: '';
            width: 193px;
            height: 1px;
            position: absolute;
            background: #000;
            bottom: -10px;
            left: 0;
          }
        }
        .btn-active {
          background: rgba(107, 209, 0, 0.34);
          border-radius: 34px;
          font-weight: 500;
          font-size: 17px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #53A200;
          border: none;
          max-width: 90px;
          margin-top: 20px;
        }
        .btn-inactive{
          background: rgba(242, 72, 34, 0.34);
          border-radius: 34px;
          font-weight: 500;
          font-size: 17px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #F24822;
          border: none;
          max-width: 90px;
          margin-top: 20px;
        }
      }
      .blog-section {
        h5 {
          font-size: 26px;
          line-height: 25px;
          text-transform: capitalize;
          color: #000000;
          margin-bottom: 25px;
          margin-top: 30px;
        }
        .user-detail {
          display: flex;
          align-items: flex-start;
          .user-icon {
            margin-right: 18px;
          }
          .user-info {
            h6 {
              font-weight: 500;
              font-size: 22px;
              line-height: 27px;
              text-transform: capitalize;
              color: #000000;
            }
            p {
              font-size: 21px;
              line-height: 27px;
              color: #000;
              margin-bottom: 0;
            }
          }
        }
      }
      .blog-detail-context {
        .body-main-text {
          font-weight: 500;
          font-size: 20px;
          line-height: 40px;
          text-align: justify;
          text-transform: capitalize;
          color: #000000;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
   }
    @media screen and (max-width: 767px) {
       .mobile-sticky {
         position: fixed;
         bottom: 0;
         left: 0;
         width: 100%;
         height: 110px;
         display: flex;
         justify-content: center !important;
         align-items: center;
         z-index: 999;
         background: #FFFFFF;
         box-shadow: 0 2px 19px rgba(32, 14, 50, 0.12);
       }
      .blog-detail-container {
        max-width: unset;
        .blog-detail-context {
          .body-main-text {
            margin-bottom: 50px;
            font-weight: 500;
            font-size: 18px;
            line-height: 32px;
            text-align: justify;
            text-transform: capitalize;
            color: #5F697A;
          }
        }
        .blog-content-wrapper {
          display: flex;
          flex-direction: column;
          margin-top: 16px;
          h5 {
            font-weight: 500;
            font-size: 17px;
            line-height: 22px;
            text-align: center;
            text-transform: capitalize;
            color: #E5C243;
          }
          .body-header-text {
            font-weight: 500;
            font-size: 19px;
            line-height: 25px;
            text-align: center;
            text-transform: capitalize;
            color: #162642;
            position: relative;
            &:after {
              content: '';
              width: 193px;
              height: 1px;
              position: absolute;
              background: #000;
              bottom: -20px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          .btn-active {
            margin: 20px auto;
          }
        }
        .blog-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          h5 {
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            text-align: center;
            text-transform: capitalize;
            color: #162642;
            margin-top: 0;
          }
          .social-links {
            margin-top: 20px;
          }
          .user-detail {
            display: flex;
            align-items: flex-start;
            .user-icon {
              margin-right: 18px;
              img {
                min-width: 52px;
                width: 52px;
                height: 52px;
              }
            }
            .user-info {
              h6 {
                font-weight: 500;
                font-size: 17px;
                line-height: 20px;
                color: #162642;
              }
              p {
                font-size: 15px;
                line-height: 20px;
                color: #162642;
                margin-bottom: 0;
              }
            }
          }
        }
      }

    }
  }
  @media screen and (min-width: 992px) and (max-width: 1366px) {
    max-width: unset;
    margin-left: 80px !important;
    padding: 0 24px;

    .blog-detail-body {
      .blog-detail-container {
        max-width: unset;
        margin-bottom: 50px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    margin-left: unset !important;
    width: 100% !important;
    padding: 0 !important;
    .reading-material-detail-header {
      margin-top: 30px;
      .back-button {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-transform: capitalize;
        color: #000000;
      }
    }
    .blog-detail-body {
      padding: 24px;
      margin-bottom: 110px;
    }
  }
}
.back-button {
  display: flex !important;
  align-items: center !important;
}
