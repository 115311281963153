.editProjectNameAndDescPage-wrapper {
    font-family: "Roboto";
    width: 100%;
  
    .mainContainer {
      max-width: 1320px;
      // min-width: 1170px;
      margin: auto;
      // @media (max-width: 1320px) {
      //   min-width: 1170px;
      // }
      @media (max-width: 767px) {
        min-width: 100%;
      }
      @media (max-width: 991px) {
        min-width: 100%;
      }
      @media (max-width: 1200px) {
        min-width: 100%;
      }
  
      .dashboard-content {
        padding: 15px 60px;
        @media (max-width: 767px) {
          padding: 50px 30px 30px 30px;
        }
  
        .updateDetailCon {
          .backCon {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
  
            @media (max-width: 767px) {
              justify-content: center;
            }
  
            .backIcon {
              cursor: pointer;
            }
            .backText {
              margin-left: 5px;
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 24px;
              text-transform: capitalize;
              color: #000000;
            }
          }
  
          .detailCardCon {
            background: #ffffff;
            box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
            border-radius: 13px;
            padding: 40px 50px 60px;
            text-align: left;
  
            @media (max-width: 767px) {
              box-shadow: none;
              padding: 25px;
            }
  
            .detailHeaderCon {
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              svg {
                margin-right: 25px;
                width: 160px;
                height: auto;
              }
              .detailHeaderText {
                font-style: normal;
                font-weight: 500;
                font-size: 27px;
                line-height: 41px;
                color: #000000;
              }
              .detailDateText {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #162642;
              }
            }
            .detailContentText {
              .contentText {
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 34px;
                color: #000000;
                margin-bottom: 30px;
              }
            }
  
            .maxConatiner {
              max-width: 750px;
              margin: auto;
  
              .editHeading {
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 27px;
                line-height: 49px;
                text-align: center;
                text-transform: capitalize;
                color: #000000;
                margin-bottom: 50px;
  
                @media (max-width: 767px) {
                  font-size: 20px;
                  line-height: 28px;
                  text-align: center;
                }
              }
  
              .fieldsCon {
                margin-bottom: 40px;
                .fieldConText {
                  font-family: Roboto;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 21px;
                  line-height: 25px;
                  color: #000000;
                  margin-bottom: 15px;
                }
                .ant-input {
                  background: #ffffff;
                  border: 1px solid #d4d4d4;
                  box-sizing: border-box;
                  border-radius: 26px;
                  min-height: 42px;
                }
                .custom-edit-textarea {
                  &.error-wrapper {
                    .ant-form-item-control-input-content {
                      // &:hover {
                      //   border: 1px solid red;
                      // }
                    }
                  }
                  .ant-form-item-control-input-content {
                    padding: 15px;
                    background-color: #fff;
                    border-radius: 20px;
                    border: 1px solid #d4d4d4;
                    textarea.ant-input {
                      border: 0;
                      border-color: transparent;
                      border-radius: 0;
                      box-shadow: none;
                      &.ant-input-status-error:not(.ant-input-disabled):not(
                          .ant-input-borderless
                        ).ant-input:focus {
                        box-shadow: none;
                      }
                    }
                  }
                }
              }
  
              .buttonContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                .ant-space {
                  gap: 20px !important;
                }
                button {
                  font-family: Roboto;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 20px;
                  line-height: 24px;
                  text-align: center;
                  text-transform: capitalize;
                  color: #000000;
                  min-width: 125px;
                  min-height: 50px;
                }
                .sendButton {
                  background: #e5c243;
                  border-radius: 53px;
                }
                .cancelButton {
                  border: 2px solid #e5c243;
                  box-sizing: border-box;
                  border-radius: 53px;
                }
              }
            }
          }
        }
      }
    }
  }
  