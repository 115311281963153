.lead-pagination{
  @media (max-width: 991px){
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .ant-pagination{
    .ant-pagination-item{
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      font-size: 15px;
      color: #727272;
    }
    .ant-pagination-next{
      .ant-pagination-item-link{
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        font-size: 15px;
        color: #727272;
      }
    }
    .ant-pagination-prev{
      .ant-pagination-item-link{
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        font-size: 15px;
        color: #727272;
      }
    }
    .ant-pagination-item-active{
      background: #E5C243;
      color: #000;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      border:none;
      font-size: 15px;
    }
  }
}
.lead-main {
  // padding: 0 40px 0 120px;
  width: 100%;
  @media (max-width: 991px){
    padding: 0;
  }
  .lead-wrapper{
    margin: 0 auto;
    @media (max-width: 991px){
      padding: 0;
      width: 100%;
    }
    .filterBoxContainer {
      @media (max-width: 991px){
        display: flex !important;
        margin: 0 22px;
        // flex-direction: column;
        // row-gap: 20px;
      }
      @media (max-width: 600px){
        flex-direction: column-reverse;
        row-gap: 20px;
      }
    }
    .lead-section{
      @media(max-width: 991px){
        margin: 50px 0 33px;
        row-gap: 35px;
      }
      .lead-icon-wrapper{
        .lead-icon-text{
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-transform: capitalize;
          color: #000000;
          padding-left: 15px;
        }
      }
      .btn-lead-wrapper {
        .custom-toggle-button{
          padding-left: 0;
          height: 40px;
          border: 2px solid #D4D4D4;
          box-sizing: border-box;
          border-radius: 20px !important;
          box-shadow: 0px 0px 13px 0px #200E322E;
          .btn-default {
            padding: 8px 25px;
            margin-bottom: 0;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            align-items: center;
            display: flex;
            @media(max-width: 320px){
              font-size: 14px;
            }
          }
          .active{
            background: #E5C243;
            color: #000;
            border: 1px solid transparent;
            padding: 8px 25px;
            font-weight: 500;
            align-items: center;
            display: flex;
            position: relative;
            z-index: 0;
          }
          .active-all{
            border-radius: 20px 0 0 20px;
          }
          .active-inactive{
            border-radius: 0 20px 20px 0;
          }
        }
      }
      .lead-input-wrapper{
        position: relative;
        @media(max-width: 991px){
          width: 55%;
          height: 100%;
        }
        @media(max-width: 600px){
          width: 100%;
        }
        .lead-input-img{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 5%;

        }
        .lead-input{
          border: 1px solid transparent;
          border-radius: 36px;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          padding: 8px 25px 8px 39px;
          box-shadow: 0px 0px 13px 0px #200E322E;
          margin-right: 21px;
          height: 40px;
          &::placeholder{
            color: #162642;
          }
          @media(max-width: 991px){
            width: 100%;
          }
        }
      }
      .blog-button{
        background: #E5C243;
        border-radius: 34px;
        font-weight: 500;
        font-size: 15px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        margin-left: 15px;
        border: none;
        padding:  6px 14px;

        .blog-button-img{
          padding-right: 10px;
        }
      }
    }
    .lead-spin.ant-spin{
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      .ant-spin-dot {
        top: 50%;
      }
      .ant-spin-dot-item{
        background-color: #E5C243;
      }
    }
  }
}
.lead-desktop-wrap{
  display: block;
  .main-lead-wrapper {
    .table-header {
      background: #000000;
      border-radius: 14px;
      height: 53px;
      color: #FFFFFF;
      margin: 40px 0;
      padding: 0 22px;
      @media(min-width: 991px) and (max-width: 1199px){
        padding: 0 12px;
      }
      .th-data {
        font-size: 15px;
        line-height: 24px;
        cursor: pointer;
        color: #fff;
        @media (min-width: 992px) and (max-width: 1199px){
          font-size: 14px;
        }
        &:first-child{
          flex: 0.25 1;
        }
        .img-icon {
          height: 8px;
          margin-left: 8px;
        }
        &:first-child {
          display: flex;
          justify-content: flex-start;
        }
        flex: 1;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
  .table-body {
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    height: 60px;
    margin-bottom: 30px;
    cursor: pointer;
    padding: 0 22px;
    @media (min-width: 992px) and (max-width: 1199px){
      padding: 0 12px;
    }
    @media(max-width: 991px){
      height: auto;
      flex-direction: column;
      padding: 22px;
    }

    .table-data {
      display: flex;
      align-items: center;
      flex: 1;
      &:first-child{
        flex: 0.2 ;
        @media(max-width: 991px){
          width: 100%;
        }
      }

      .blog-card{
        @media(max-width: 991px){
          width: 300px;
          justify-content: unset;
          margin-bottom: 20px;
        }
        @media (max-width: 320px){
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .table-data-label{
          @media(max-width: 991px){
            width: 160px;
          }
          @media (max-width: 320px){
            width: unset;
          }
        }
        .project{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background: #E5C243;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .email{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #2462CD;
          img{
            width: 14px;
            height: 14px;
            margin-left: 5px;
          }
        }
        .date{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
        }
        .stage{
          font-weight: 500;
          font-size: 17px;
          line-height: 27px;
          text-transform: capitalize;
          color: #000000;
          .stage-number{
            width: 21px;
            height: 21px;
            border-radius: 50%;
            background: #E5C243;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 15px;
            line-height: 24px;
            opacity: 1;
            margin-right: 10px;
          }
        }
      }

      .blog-button-active{
        background: rgba(107, 209, 0, 0.34);
        border-radius: 34px;
        font-weight: 500;
        font-size: 12px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #53A200;
        padding: 0 11px;
        border: none;

      }
      .blog-button-draft{
        background: rgba(242, 72, 34, 0.34);
        border-radius: 34px;
        font-weight: 500;
        font-size: 12px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #F24822;
        padding: 0 11px;
        border: none;
      }
      .action-img{
        width: 39px;
        margin: 0 5px;
      }
      .username {
        column-gap: 15px;
        .user-icon{
          background: #E5C243;
          width: 45px;
          height: 45px;
          border-radius: 50%;
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          color: #162642;
          margin-bottom: 0;
        }
        .user-name{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
          margin-bottom: 0;
          @media (min-width: 991px){
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .blog-img{
          width: 48px;
          margin-right: 0px;
          border-radius: 50%;
          @media(min-width: 992px) and (max-width: 1199px){
            width: 33px;
            margin-right: 5px;
          }
          @media (max-width: 991px){
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
      span {
        height: 41px;
        width: 41px;
        background: #3E6295;
        border-radius: 50%;
        font-weight: 500;
        font-size: 23px;
        line-height: 24px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.4;
      }
    }
    .blog-action{
      @media (max-width: 991px){
        margin-top: 10px;
      }
    }
  }
}
.lead-filter-main{
  position: relative;
  .lead-filter-wrapper{

    .btn-secondary{
      background: unset;
      border: unset;
      img{
        position: absolute;
        bottom: 0px;
        right: 15px;
        width: 100px;
      }
    }
  }
  .lead-filter-menu{
    width: 100%;
    padding: 45px 30px;
    background: #FFFFFF;
    position: absolute;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.19);
    border-radius: 13px;
    border: unset;
    transform: translate3d(0, 127px, -16px) !important;
    .lead-filter-cancel{
      position: relative;
      margin-bottom: 26px;
      .lead-filter-text{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
      .lead-cancel{
        position: absolute;
        top: -85%;
        right: -6%;
      }
    }

    .lead-dashboard-button{
      display: flex;
      flex-wrap: wrap;

    }
    .lead-input-wrapper{
      position: relative;
      margin-left: 6px;
      @media(max-width: 991px){
        height: 100%;
      }
      .lead-input-img{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5%;
      }
      .lead-input{
        border: 1px solid #000000;
        border-radius: 36px;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        padding: 8px 15px 8px 45px;
        &::placeholder{
          color: #162642 !important;
        }
        @media(max-width: 991px){
          width: 100%;
        }
      }
    }
    .btn-lead-wrapper {
      @media(max-width: 991px){
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
      }
      @media(max-width: 767px){
        width: 100%;
      }
      .custom-toggle-button{
        padding-left: 0;
        height: 40px;
        border: 2px solid #D4D4D4;
        box-sizing: border-box;
        border-radius: 20px !important;
        .btn-default {
          padding: 8px 35px;
          margin-bottom: 0;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          align-items: center;
          display: flex;
          @media(max-width: 320px){
            font-size: 14px;
          }
        }
        .btn-default.active{
          &:first-child{
            border-radius: 20px;
          }
        }
        .active{
          background: #E5C243;
          color: #000;
          border: 1px solid transparent;
          padding: 8px 25px;
          font-weight: 500;
          align-items: center;
          display: flex;
          position: relative;
          z-index: 0;
        }
      }
    }
    .lead-apply-btn{
      background: #E5C243;
      border-radius: 53px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: capitalize;
      color: #000000;
      margin-top: 34px;
      width: 100%;
      border: unset;
      &:focus{
        border: unset;
        box-shadow: unset;
      }
    }
  }
}