.verify-email-wrapper{
  background: #FFFFFF;
  box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
  border-radius: 13px;
  padding: 200px;
  margin-bottom: 50px;
  @media (min-width: 992px) and (max-width: 1199px){
    padding: 120px;
  }
  @media (min-width: 768px) and (max-width: 991px){
    padding: 80px 50px;
  }
  @media (max-width: 767px){
    padding: 100px 40px;
  }
  .verify-email-text{
    font-weight: 500;
    font-size: 27px;
    line-height: 39px;
    text-align: center;
    color: #000000;
    @media (max-width: 767px) {
      font-size: 21px;
      line-height: 33px;
    }
  }
}