.error-page {
  margin-top: 10%;
  text-align: center;
  animation: none;
  .error-404 {
    color: #162642;
    font-size: 200px !important;
  }

  .return-to-home-404 {
    text-decoration: none !important;
    .r2h-button {
      background-color: #162642;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      padding: 10px;
      border-radius: 5px;
      font-size: 20px;
      color: white;
      border: none;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .error-page {
    .error-404 {
      font-size: 180px !important;
    }

    .subtitle-404 {
      font-size: 14px !important;
    }
  }
}
