.team-master-main {
  width: 100%;
  .team-master-wrapper {
    @media(max-width: 991px){
      padding: 0 20px;
      margin: 50px 0;
    }

    .add-team-master-main{
      padding: 30px;
      background: #ffffff;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      border-radius: 13px;
      @media (max-width: 991px) {
        padding: 50px 20px;
        width: 100%;
      }
      .team-master-section {
        @media (min-width: 992px) and (max-width: 1199px){
          flex-direction: column;
          row-gap: 35px;
        }
        .team-icon-wrapper {
          .team-icon-text {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-transform: capitalize;
            color: #000000;
            padding-left: 15px;
          }
        }
        .btn-team-wrapper {
          //width: 285px;
          @media (max-width: 767px) {
            width: 100%;
          }
          .custom-toggle-button {
            .team-select {
              border: 1px solid #000000;
              box-sizing: border-box;
              border-radius: 36px;
              font-weight: normal;
              font-size: 13px;
              line-height: 24px;
              color: #162642;
              background: #fff;
              padding: 8px 17px;
              margin-right: 15px;
              @media (min-width: 992px) and (max-width: 1199px){
                margin-right: 8px;
                padding: 8px 10px;
              }
            }
            .team-input-wrapper {
              position: relative;
              @media (max-width: 991px) {
                width: 55%;
                height: 100%;
              }
              .team-input-img {
                width: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 8%;
              }
              .team-input {
                border: 1px solid #000000;
                box-sizing: border-box;
                border-radius: 36px;
                font-weight: normal;
                font-size: 13px;
                line-height: 24px;
                padding: 8px 25px 8px 39px;
                margin-right: 21px;
                width: 145px;
                &::placeholder {
                  color: #162642;
                }
                @media (max-width: 991px) {
                  width: 100%;
                }
              }
            }
          }
        }

        .blog-button {
          background: #e5c243;
          border-radius: 34px;
          font-weight: 500;
          font-size: 15px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          margin-left: 15px;
          border: none;
          padding: 6px 14px;

          .blog-button-img {
            padding-right: 10px;
          }
        }
      }
      .add-team-desktop-wrap {
        display: block;
        .main-team-wrapper {
          .table-header {
            background: #000000;
            border-radius: 14px;
            height: 53px;
            color: #ffffff;
            margin: 40px 0;
            padding: 0 22px;
            @media (min-width: 991px) and (max-width: 1199px) {
              padding: 0 12px;
            }
            .th-data {
              font-size: 15px;
              line-height: 24px;
              cursor: pointer;
              color: #fff;
              @media (min-width: 992px) and (max-width: 1199px) {
                font-size: 14px;
              }
              &:first-child {
                flex: 0.25 1;
              }
              .img-icon {
                height: 8px;
                margin-left: 8px;
              }
              &:first-child {
                display: flex;
                justify-content: flex-start;
              }
              flex: 1;
              display: flex;
              justify-content: flex-start;
              position: relative;
              .add-team-head-checkbox {
                //margin-right: 15px;
                opacity: 0;
                cursor: pointer;
                z-index: 2;
                &:checked ~ .checkmark {
                  background: #E5C243;
                  border: 2px solid transparent;
                  &::after {
                    display: block;
                  }
                }
              }
              .checkmark {
                position: absolute;
                top: -3px;
                left: 0;
                border: 2px solid #fff;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                cursor: pointer;
                z-index: 1;
                margin-bottom: 0;
                &::after {
                  content: "";
                  position: absolute;
                  display: none;
                  left: 7px;
                  top: 4px;
                  width: 1px;
                  height: 8px;
                  border: solid #000;
                  border-width: 0 2px 0 0;
                  -webkit-transform: rotate(90deg);
                  -ms-transform: rotate(90deg);
                  transform: rotate(90deg);
                }
              }
            }
          }
        }
        .table-body {
          background: #ffffff;
          box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
          border-radius: 13px;
          height: 60px;
          margin-bottom: 30px;
          cursor: pointer;
          padding: 0 22px;
          @media (min-width: 992px) and (max-width: 1199px) {
            padding: 0 12px;
          }
          @media (max-width: 991px) {
            height: auto;
            flex-direction: column;
            padding: 22px;
            margin: 30px 0;
          }
          .table-data {
            display: flex;
            align-items: center;
            flex: 1;
            position: relative;
            &:first-child {
              flex: 0.2;
              @media (max-width: 991px) {
                width: 100%;
              }
            }
            .test-checked{
              border-radius: 50%;
              &:checked + label{
                background: black;
              }
            }
            .add-team-checkbox {
              //margin-right: 15px;
              opacity: 0;
              cursor: pointer;
              z-index: 2;
              &:checked ~ .checkmark {
                background: #E5C243;
                border: 2px solid transparent;
                &::after {
                  display: block;
                }
              }
            }
            .checkmark {
              position: absolute;
              top: -3px;
              left: 0;
              border: 2px solid rgba(0, 0, 0, 0.23);
              border-radius: 50%;
              width: 20px;
              height: 20px;
              cursor: pointer;
              z-index: 1;
              margin-bottom: 0;
              &::after {
                content: "";
                position: absolute;
                display: none;
                left: 6px;
                top: 3px;
                width: 4px;
                height: 8px;
                border: solid #000;
                border-width: 0 1px 1px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }

            .blog-card {
              @media (max-width: 991px) {
                width: 300px;
                justify-content: unset;
                margin-bottom: 20px;
              }
              @media (max-width: 320px) {
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
              .table-data-label {
                @media (max-width: 991px) {
                  width: 160px;
                }
                @media (max-width: 320px) {
                  width: unset;
                }
              }
              .project {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #162642;
                width: 26px;
                height: 26px;
                border-radius: 50%;
                background: #e5c243;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .department {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #162642;
                margin-bottom: 0;
              }
              .email {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #2462cd;
                img {
                  width: 14px;
                  height: 14px;
                  margin-left: 5px;
                }
              }
              .date {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #162642;
              }
              .stage {
                font-weight: 500;
                font-size: 17px;
                line-height: 27px;
                text-transform: capitalize;
                color: #000000;
                .stage-number {
                  width: 21px;
                  height: 21px;
                  border-radius: 50%;
                  background: #e5c243;
                  color: #000;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: bold;
                  font-size: 15px;
                  line-height: 24px;
                  opacity: 1;
                  margin-right: 10px;
                }
              }
            }

            .blog-button-active {
              background: rgba(107, 209, 0, 0.34);
              border-radius: 34px;
              font-weight: 500;
              font-size: 12px;
              line-height: 27px;
              text-align: center;
              text-transform: capitalize;
              color: #53a200;
              padding: 0 11px;
              border: none;
            }
            .blog-button-draft {
              background: rgba(242, 72, 34, 0.34);
              border-radius: 34px;
              font-weight: 500;
              font-size: 12px;
              line-height: 27px;
              text-align: center;
              text-transform: capitalize;
              color: #f24822;
              padding: 0 11px;
              border: none;
            }
            .action-img {
              width: 39px;
              margin: 0 5px;
            }
            .username {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #162642;
              @media (max-width: 991px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 15px;
              }
              .blog-img {
                width: 48px;
                margin-right: 15px;
                @media (min-width: 992px) and (max-width: 1199px) {
                  width: 33px;
                  margin-right: 5px;
                }
                @media (max-width: 991px) {
                  margin-right: 0;
                  margin-bottom: 10px;
                }
              }
            }
            span {
              height: 41px;
              width: 41px;
              background: #3e6295;
              border-radius: 50%;
              font-weight: 500;
              font-size: 23px;
              line-height: 24px;
              color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              opacity: 0.4;
            }
          }
          .blog-action {
            @media (max-width: 991px) {
              margin-top: 10px;
            }
          }
        }
      }

      .team-buttons {
        .btn-cancel {
          border: 2px solid #e5c243;
          box-sizing: border-box;
          border-radius: 53px;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          background: transparent;
          text-transform: capitalize;
          color: #000000;
          padding: 10px 30px;
        }
        .btn-add{
          background: #E5C243;
          border-radius: 53px;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          padding: 10px 30px;
          border: 2px solid transparent;
          margin-left: 30px;
        }
      }
    }
  }
  .team-filter-main {
    position: relative;
    .team-filter-wrapper {
      .btn-secondary {
        background: unset;
        border: unset;
        img {
          position: absolute;
          bottom: 0px;
          right: 15px;
          width: 100px;
        }
      }
    }
    .team-filter-menu {
      width: 100%;
      padding: 45px 30px;
      background: #ffffff;
      position: absolute;
      box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.19);
      border-radius: 13px;
      border: unset;
      transform: translate3d(0, 127px, -16px) !important;
      .team-filter-cancel {
        position: relative;
        margin-bottom: 26px;
        .team-filter-text {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }
        .team-cancel {
          position: absolute;
          top: -85%;
          right: -6%;
        }
      }

      .team-dashboard-button {
        display: flex;
        flex-wrap: wrap;
      }
      .team-input-wrapper {
        position: relative;
        margin-left: 6px;
        @media (max-width: 991px) {
          width: 100%;
          flex-direction: column;
          row-gap: 15px;
        }
        .flter-team-select {
          border: 1px solid #000000;
          box-sizing: border-box;
          border-radius: 36px;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          color: #162642;
          background: #fff;
          padding: 8px 17px;
          margin-right: 0;
          @media (min-width: 992px) and (max-width: 1199px){
            margin-right: 8px;
            padding: 8px 10px;
          }
          @media(max-width: 991px){
            width: 100%;
          }
          @media (max-width: 375px){
            margin-right: 0;
            //margin-bottom: 15px;
          }
        }
        .filter-team-input-wrapper {
          position: relative;
          @media (max-width: 991px) {
            width: 100%;
            height: 100%;
          }
          .team-input-img {
            width: 20px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 8%;
            @media(max-width: 991px){
              left: 3%;
            }
          }
          .team-input {
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 36px;
            font-weight: normal;
            font-size: 13px;
            line-height: 24px;
            padding: 8px 25px 8px 39px;
            margin-right: 21px;
            width: 145px;
            &::placeholder {
              color: #162642;
            }
            @media (max-width: 991px) {
              width: 100%;
            }
          }
        }
      }
      .mobile-filter{
        @media (max-width: 375px){
          flex-direction: column;
          row-gap: 15px;
        }
      }
    }
  }
}


