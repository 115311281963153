.email-temp-wrapper{
  width: 697px;
  margin: 0 auto;
  padding: 50px 0;
  @media (max-width: 767px){
    width: 100%;
  }
  .email-temp-img{
    margin-bottom: 45px;
  }
  .email-temp-main{
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.18);
    border-radius: 31px 31px 0 0;
    padding: 0 134px;
    @media (max-width: 767px){
      padding: 0 50px;
    }
    .email-temp-name{
      margin-top: 65px;
      .email-temp-text{
        font-weight: 500;
        font-size: 27px;
        color: #162642;
      }
      .email-temp-desc{
        font-weight: normal;
        font-size: 15px;
        line-height: 21px;
        color: #000000;
      }
      .email-temp-button{
        background: #E5C243;
        padding: 14px 40px;
        font-weight: bold;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        color: #000000;
        width: 355px;
        border-radius: 48px;
        border: none;
        margin-top: 26px;
        @media(max-width: 767px){
          width: 100%;
        }
      }
    }
    .email-temp-message{
      margin-top: 35px;
      margin-bottom: 75px;
      .email-temp-msg{
        .message{
          font-weight: normal;
          font-size: 15px;
          line-height: 24px;
          color: #000000;
        }
        .email{
          color: #E5C243;
          font-size: 17px;
          line-height: 20px;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
    .email-temp-greet{
      .email-temp-thankyou{
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
        color: #000000;
      }
    }
  }
  .email-temp-footer{
    padding: 25px 134px;
    background: #000000;
    border-radius: 0 0 31px 31px;
    @media (max-width: 767px){
      padding: 20px 50px;
    }
    .email-temp-social{}
    .email-temp-email{
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      color: #E5C243;
      .info-email{
        font-weight: normal;
        font-size: 12px;
        line-height: 21px;
        color: #E5C243;
        &:hover{
          text-decoration: none;
        }
      }
    }
    .email-temp-copyrights{
      @media (max-width: 767px){
        flex-direction: column;
        justify-content: center;
      }
      .rights{
        font-weight: 300;
        font-size: 11px;
        line-height: 21px;
        color: #FFFFFF;
      }
      .conditions{
        font-weight: 300;
        font-size: 11px;
        line-height: 21px;
        color: #E5C243;
        border-left: 1px solid #fff;
        padding-left: 5px;
        margin-left: 5px;
      }
      .policy{
        font-weight: 300;
        font-size: 11px;
        line-height: 21px;
        color: #E5C243;
        border-left: 1px solid #fff;
        padding-left: 5px;
        margin-left: 5px;
      }
    }
  }
}