.blog-main {
  width: 100%;
  .blog-wrapper{
    margin: 0 auto;
    @media (max-width: 991px){
      padding: 0;
      width: 100%;
    }
    .blog-section{
      @media(max-width: 991px){
        margin: 50px 0 33px;
        row-gap: 35px;
      }
      .blog-icon-wrapper{
        .blog-icon-text{
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-transform: capitalize;
          color: #000000;
          padding-left: 15px;
        }
      }
      .blogListCon {
        @media (max-width: 991px) {
          padding: 0 22px;
          justify-content: center;
        }
      }
      .blog-input-warpper{
        position: relative;
        @media(max-width: 991px){
          width: 55%;
          height: 100%;
        }
        .blog-input-img{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 5%;
          width: 17px;
        }
        .blog-input{
          border: 1px solid #000000;
          border-radius: 36px;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          padding: 8px 25px 8px 35px;
          &::placeholder{
            color: #162642;
          }
          @media(max-width: 991px){
            width: 100%;
          }
        }
      }
      .blog-button{
        background: #E5C243;
        border-radius: 34px;
        font-weight: 500;
        font-size: 15px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        margin-left: 15px;
        border: none;
        padding:  6px 14px;

        .blog-button-img{
          padding-right: 10px;
        }
      }
    }
  }
  .investor-desktop-wrap{
    display: block;
    position: relative;
    .investor-spin{
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      .ant-spin-dot-item{
        background-color: #E5C243;
      }
    }
    .main-table-wrapper {
      .table-header {
        background: #000000;
        border-radius: 14px;
        height: 53px;
        color: #FFFFFF;
        margin: 40px 0;
        padding: 0 22px;
        .th-data {
          font-size: 15px;
          line-height: 24px;
          cursor: pointer;
          color: #fff;
          &:first-child{
            flex: 0.25 1;
          }
          .img-icon {
            height: 8px;
            margin-left: 8px;
          }
          &:first-child {
            display: flex;
            justify-content: flex-start;
          }
          flex: 1;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
  }

  .table-body {
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    height: 60px;
    margin-bottom: 30px;
    cursor: pointer;
    padding: 0 22px;
    @media(max-width: 991px){
      height: auto;
      flex-direction: column;
      padding: 22px;
      margin: 22px;
    }
    //.date {
    //  flex: 1;
    //  display: flex;
    //  justify-content: flex-start;
    //  &:last-child {
    //    font-weight: bold;
    //    font-size: 16px;
    //    line-height: 24px;
    //    color: #7C8E54;
    //  }
    //}
    .table-data {
      display: flex;
      align-items: center;
      flex: 1;
      &:first-child{
        flex: 0.2 ;
        @media(max-width: 991px){
          width: 100%;
        }
      }
      .blog-card{
        @media(max-width: 991px){
          width: 300px;
          justify-content: space-between;
          margin-bottom: 20px;
        }
        @media (max-width: 320px){
          width: 200px;
        }
        .date{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
        }
        .technology{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #162642;
        }
        .job{
          font-weight: 500;
          font-size: 17px;
          line-height: 27px;
          text-transform: capitalize;
          color: #000000;
        }
      }

      .blog-button{
        background: rgba(107, 209, 0, 0.34);
        border-radius: 34px;
        font-weight: 500;
        font-size: 12px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #53A200;
        padding: 0 11px;
        border: none;
        @media (max-width: 991px){
          margin-bottom: 25px;
        }
      }
      .blog-button-draft{
        background: rgba(242, 72, 34, 0.34);
        border-radius: 34px;
        font-weight: 500;
        font-size: 12px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #F24822;
        padding: 0 11px;
        border: none;
      }
      .action-img{
        width: 39px;
        margin: 0 5px;
      }
      .username {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #162642;
        @media(max-width: 991px){
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
        }
        .blog-img{
          width: 48px;
          border-radius: 50%;
          margin-right: 15px;
          height: 48px;
          @media(min-width: 992px) and (max-width: 1199px){
            width: 33px;
            margin-right: 5px;
          }
          @media (max-width: 991px){
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
      span {
        // height: 41px;
        // width: 41px;
        background: #3E6295;
        border-radius: 50%;
        font-weight: 500;
        font-size: 23px;
        line-height: 24px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.8;
      }
    }
    .blog-action{
      @media (max-width: 991px){
        margin-top: 10px;
      }
    }
  }
  .client-feedback-pagination{
    margin: 50px 0 80px;
    .ant-pagination{
      .ant-pagination-item{
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        font-size: 15px;
        color: #727272;
      }
      .ant-pagination-next{
        .ant-pagination-item-link{
          background: #FFFFFF;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          font-size: 15px;
          color: #727272;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ant-pagination-prev{
        .ant-pagination-item-link{
          background: #FFFFFF;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
          border-radius: 50%;
          font-size: 15px;
          color: #727272;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ant-pagination-item-active{
        background: #E5C243;
        color: #000;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        border:none;
        font-size: 15px;
      }
    }
  }
}