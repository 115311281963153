.team-detail-main-layout {
  display: flex;
  flex-direction: column;
  .reading-material-detail-header {
    .header-left {
      .back-button {
        display: flex;
        align-items: center;
        .anticon {
          margin-right: 5px;
          cursor: pointer;
        }
      }
    }
  }
  .blog-detail-body {
    width: 100%;
    margin: 40px auto;
    padding: 35px 0 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 19px 0px #200E321F;
    border-radius: 13px;
    .mark-btn {
      background: #E5C243;
      font-weight: 500;
      font-size: 17px;
      line-height: 24px;
      color: #000000;
      border: 1px solid transparent;
      border-radius: 25px;
      margin-right: 16px;
      &:hover {
        background: transparent;
        border: 1px solid #E5C243;
        color: #E5C243;
      }
    }
    .blog-detail-container {
      @media(max-width: 991px){
        padding: 0 20px;
      }
      .blog-content-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        h5 {
          color: #E5C243;
          font-size: 26px;
          line-height: 22px;
          text-transform: capitalize;
        }
        .body-header-text {
          font-weight: 500;
          font-size: 28px;
          line-height: 44px;
          text-transform: capitalize;
          color: #000000;
          position: relative;
          &:after {
            content: '';
            width: 193px;
            height: 1px;
            position: absolute;
            background: #000;
            bottom: -10px;
            left: 0;
          }
        }
        .btn-active {
          background: rgba(107, 209, 0, 0.34);
          border-radius: 34px;
          font-weight: 500;
          font-size: 17px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #53A200;
          border: none;
          max-width: 90px;
          margin-top: 20px;
        }
      }
      .blog-section {
        border-bottom: 1px solid #C4C4C4;
        padding: 0 20px;
        margin-bottom: 33px;
        h5 {
          font-size: 26px;
          line-height: 25px;
          text-transform: capitalize;
          color: #000000;
          margin-bottom: 25px;
          margin-top: 30px;
        }
        .user-detail {
         width: 100%;
          .user-icon {
            margin-right: 18px;
          }
          .user-info {
            h6 {
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              color: #162642;
              margin-bottom: 5px;
            }
            p {
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: #162642;
              margin-bottom: 0;
            }
          }
        }
      }
      
      .team-detail-context {
        border-bottom: 1px solid #C4C4C4;
        padding: 0 20px 33px;
        margin-bottom: 33px;
        @media(max-width: 767px){
          padding: 0 0 33px;
        }
        .team-department-text{
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: #000000;
          margin-bottom: 28px;
          @media(max-width: 991px){
            margin-bottom: 0;
          }
        }
        .team-department-button{
          border: 1.5px solid #E5C243;
          box-sizing: border-box;
          border-radius: 53px;
          font-weight: normal;
          font-size: 16px;
          line-height: 17px;
          text-align: center;
          text-transform: capitalize;
          color: #162642;
          padding: 8px 18px;
          background: transparent;
          margin: 0 8px;
          &:focus{
            border: none;
            box-shadow: unset;
          }
          @media(max-width: 991px){
            margin-top: 25px;
          }
          @media(max-width: 767px){
            margin: 12px 4px 0;
          }
        }
        .team-department-button-active{
          border: 1.5px solid #E5C243;
          box-sizing: border-box;
          border-radius: 53px;
          font-weight: normal;
          font-size: 16px;
          line-height: 17px;
          text-align: center;
          text-transform: capitalize;
          color: #162642;
          padding: 8px 18px;
          background: #E5C243;
          margin: 0 8px;
          &:focus{
            box-shadow: unset;
          }
          @media(max-width: 991px){
            margin-top: 25px;
          }
          @media(max-width: 767px){
            margin: 12px 4px 0;
          }
          img{
            margin-left: 8px;
          }
        }
      }
      .team-project-context{
        border-bottom: 1px solid #C4C4C4;
        padding: 0 20px 33px;
        margin-bottom: 33px;
        @media(max-width: 767px){
          padding: 0 0 33px;
        }
        .team-project-text{
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: #000000;
          margin-bottom: 28px;
          @media(max-width: 991px){
            margin-bottom: 0;
          }
        }
        .team-project-button{
          border: 1.5px solid #E5C243;
          box-sizing: border-box;
          border-radius: 53px;
          font-weight: normal;
          font-size: 16px;
          line-height: 17px;
          text-align: center;
          text-transform: capitalize;
          color: #162642;
          padding: 8px 20px;
          background: transparent;
          margin: 0 8px;
          margin-bottom: 20px;
          &:focus{
            box-shadow: unset;
          }
          @media(max-width: 991px){
            margin-top: 25px;
          }
          @media(max-width: 767px){
            margin: 12px 4px 0;
          }
        }
        .team-project-button-active{
          border: 1.5px solid #E5C243;
          box-sizing: border-box;
          border-radius: 53px;
          font-weight: normal;
          font-size: 16px;
          line-height: 17px;
          text-align: center;
          text-transform: capitalize;
          color: #162642;
          padding: 8px 18px;
          background: #E5C243;
          margin: 0 8px;
          margin-bottom: 20px;
          &:focus{
            box-shadow: unset;
          }
          @media(max-width: 991px){
            margin-top: 25px;
          }
          @media(max-width: 767px){
            margin: 12px 4px 0;
          }
          img{
            margin-left: 8px;
            margin-bottom: 2px;
          }
        }
      }
      .team-permissions-context{
        padding: 0 20px;
        margin-bottom: 33px;
        @media(max-width: 767px){
          padding: 0;
        }
        .team-permission-text{
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: #000000;
        }
        .team-permission-button{
          border: 1.5px solid #E5C243;
          box-sizing: border-box;
          border-radius: 53px;
          font-weight: normal;
          font-size: 16px;
          line-height: 17px;
          text-align: center;
          text-transform: capitalize;
          color: #162642;
          padding: 8px 20px;
          background: transparent;
          margin: 25px 8px 0;
          &:focus{
            box-shadow: unset;
          }
          @media(max-width: 767px){
            margin: 12px 4px 0;
          }
        }
        .team-permission-button-active{
          border: 1.5px solid #E5C243;
          box-sizing: border-box;
          border-radius: 53px;
          font-weight: normal;
          font-size: 16px;
          line-height: 17px;
          text-align: center;
          text-transform: capitalize;
          color: #162642;
          padding: 8px 18px;
          background: #E5C243;
          margin: 25px 8px 0;
          &:focus{
            box-shadow: unset;
          }
          @media(max-width: 767px){
            margin: 12px 4px 0;
          }
          img{
            margin-left: 8px;
            margin-bottom: 2px;
          }
        }

      }

    }

    @media screen and (max-width: 767px) {
      .mobile-sticky {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 110px;
        display: flex;
        justify-content: center !important;
        align-items: center;
        z-index: 999;
        background: #FFFFFF;
        box-shadow: 0 2px 19px rgba(32, 14, 50, 0.12);
      }
      .blog-detail-container {
        max-width: unset;
        .blog-content-wrapper {
          display: flex;
          flex-direction: column;
          margin-top: 16px;
          h5 {
            font-weight: 500;
            font-size: 17px;
            line-height: 22px;
            text-align: center;
            text-transform: capitalize;
            color: #E5C243;
          }
          .body-header-text {
            font-weight: 500;
            font-size: 19px;
            line-height: 25px;
            text-align: center;
            text-transform: capitalize;
            color: #162642;
            position: relative;
            &:after {
              content: '';
              width: 193px;
              height: 1px;
              position: absolute;
              background: #000;
              bottom: -20px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          .btn-active {
            margin: 20px auto;
          }
        }
        .blog-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          h5 {
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            text-align: center;
            text-transform: capitalize;
            color: #162642;
            margin-top: 0;
          }
          .social-links {
            margin-top: 20px;
          }
          .user-detail {
            .user-icon {
              margin-right: 0;
              margin-bottom: 10px;
              img {
                min-width: 52px;
                width: 52px;
                height: 52px;
              }
            }
            .user-info {
              margin-bottom: 25px;
              h6 {
                font-weight: 500;
                font-size: 17px;
                line-height: 20px;
                color: #162642;
              }
              p {
                font-size: 15px;
                line-height: 20px;
                color: #162642;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  .add-blog-buttons{
    padding: 18px 54px;
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    margin-top: 40px;
    @media (max-width: 767px){
      padding: 20px 40px;
      row-gap: 19px;
    }
    .button-group{
      width: 100%;
      @media(max-width: 767px){
        flex-direction: row;
      }
      @media (max-width: 375px){
        flex-direction: column;
        row-gap: 19px;
      }
      .button-cancel{
        border: 2px solid #E5C243;
        box-sizing: border-box;
        border-radius: 53px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        padding: 6px 23px;
        margin-left: 15px;
        background: transparent;
        @media(max-width: 375px){
          margin-left: 0;
          width: 100%;
        }
      }
      .button-save{
        background: #E5C243;
        border-radius: 53px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        padding: 8px 23px;
        border: none;
        @media(max-width: 375px){
          width: 100%;
        }
      }
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1366px) {
    max-width: unset;
    .blog-detail-body {
      .blog-detail-container {
        max-width: unset;
        //margin-bottom: 50px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    margin-left: unset !important;
    width: 100% !important;
    .reading-material-detail-header {
      margin-top: 30px;
      .back-button {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-transform: capitalize;
        color: #000000;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 24px !important;
  }
}

.btn-toggle-wrapper {
  width: 285px;
  @media(max-width: 767px){
    width: 100%;
  }
  .custom-team-toggle-button{
    padding-left: 0;
    height: 40px;
    box-sizing: border-box;
    margin-bottom: 0;
    border-radius: 20px !important;
    background: rgba(178, 178, 178, 0.24);
    .btn-default {
      border-radius: 61px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #000;
      align-items: center;
      display: flex;
      margin: 0;
      padding: 0 18px;
      @media(max-width: 320px){
        font-size: 14px;
      }
    }
    .active{
      background: #53A100;
      color: #fff;
      border-radius: 20px !important;
      border: 1px solid transparent;
      padding: 0 18px;
      font-weight: 500;
      align-items: center;
      display: flex;
      margin: 0;
    }
  }
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: 0em !important;
}