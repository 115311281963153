.agreement-detail-wrapper{
  width: 100%;
  .agreement-detail-main{
    width: 100%;
    
    @media( max-width: 767px){
      padding: 0;
      width: 100%;
      background: #fff;
    }
    .header-left{
      margin-bottom: 25px;
      @media(max-width: 991px){
        margin: 25px 15px;
        padding: 0 30px;
      }
      @media(max-width: 375px){
        flex-direction: column;
        row-gap: 15px;
        align-items: flex-start !important;
      }
      .back-button{
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-transform: capitalize;
        color: #000000;
        span{
          margin-right: 5px;
        }
      }
      .agreements-templates{
        .agreements-templates-text{
          font-weight: 500;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          margin-right: 10px;
          @media (max-width: 425px){
            font-size: 15px;
          }
        }
        .agreements-templates-sow{
          font-weight: 500;
          font-size: 17px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          border: 2px solid #E5C243;
          box-sizing: border-box;
          border-radius: 34px;
          padding: 5px 20px;
          margin-right: 10px;
          @media (max-width: 425px){
            font-size: 15px;
          }
        }
        .agreements-templates-msa{
          font-weight: 500;
          font-size: 17px;
          line-height: 27px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          border: 2px solid #E5C243;
          box-sizing: border-box;
          border-radius: 34px;
          padding: 5px 20px;
          @media (max-width: 425px){
            font-size: 15px;
          }
        }
      }
    }
    .agreement-main-wrapper{
      @media(min-width: 768px) and (max-width: 991px){
        padding: 0 30px;
      }
      @media(max-width: 767px){
        padding: 0 15px;
      }
      .agreement-title-wrapper{
        background: #000;
        color: #fff;
        padding: 22px 33px;
        border-radius: 13px 13px 0 0;
        @media( max-width: 767px){
          padding: 22px 20px;
          margin: 0 15px;
        }
        .agreement-title{
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-transform: capitalize;
          color: #fff;
          margin-bottom: 0;
          @media (max-width: 767px){
            text-align: center;
            //margin-bottom: 25px;
          }
          img{
            margin-right: 10px;
          }
        }
      }
      .agreement-card-main{
        background: #FFFFFF;
        box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
        border-radius: 0 0 13px 13px;
        padding: 50px 0 0;
        margin-bottom: 50px;
        @media(max-width: 767px){
          padding: 10px 0 0;
          margin: 0 15px;
        }
        .add-agreement-card{
          padding: 20px 50px 80px;
          @media (min-width: 768px) and (max-width: 991px){
            padding: 20px 30px 80px;
          }
          @media(max-width: 767px){
            padding: 20px 20px 50px;
          }
          .agreement-form{
            .agreement-form-group{
              margin-bottom: 20px;
              @media (max-width: 991px){
                margin-top: 20px;
              }
              @media (max-width: 767px){
                margin-top: 35px;
              }
              .agreement-label{
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                color: #000000;
              }
              .agreement-input{
                background: #FFFFFF;
                border: 2px solid #D4D4D4;
                box-sizing: border-box;
                border-radius: 38px;
                width: 295px;
                height: 38px;
                &:focus{
                  box-shadow: unset;
                }
                @media (max-width: 767px){
                  width: 100%;
                }
              }
              .agreement-textarea{
                background: #FFFFFF;
                border: 2px solid #D4D4D4;
                box-sizing: border-box;
                border-radius: 38px;
                height: 300px;
                &:focus{
                  box-shadow: unset;
                }
              }
            }
          }
        }
        .agreement-buttons{
          padding: 18px 54px;
          background: #FFFFFF;
          border-radius: 13px;
          margin: 40px 0 20px;
          @media (max-width: 767px){
            padding: 20px 0;
            flex-direction: column-reverse;
            row-gap: 19px;
          }
          .button-group{
            width: 100%;
            @media(max-width: 767px){
              flex-direction: row;
              column-gap: 10px;
            }
            @media (max-width: 375px){
              flex-direction: column;
              row-gap: 19px;
            }
            .button-cancel{
              border: 2px solid #E5C243;
              box-sizing: border-box;
              border-radius: 53px;
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              text-align: center;
              text-transform: capitalize;
              color: #000000;
              padding: 11px 28px;
              background: transparent;
              @media (min-width: 768px) and (max-width: 991px){
                padding: 10px 25px;
                font-size: 18px;
              }
              @media(max-width: 767px){
                padding: 10px 45px;
              }
              @media(max-width: 375px){
                width: 100%;
              }
            }
            .button-save{
              background: #E5C243;
              border-radius: 53px;
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              text-align: center;
              text-transform: capitalize;
              color: #000000;
              padding: 13px 24px;
              margin-right: 15px;
              border: none;
              img{
                margin-right: 10px;
              }
              @media (min-width: 768px) and (max-width: 991px){
                padding: 12px 20px;
                font-size: 18px;
              }
              @media (max-width: 767px){
                padding: 10px 30px;
                margin-right: 0;
              }
              @media(max-width: 375px){
                width: 100%;
              }
            }

          }
        }
      }
      .add-agreement-footer{
        background: #FFFFFF;
        box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
        border-radius: 0px 0px 13px 13px;
        padding: 35px;
        .agreement-text-wrapper{
          .agreement-detail-text{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
            margin-left: 7px;
            @media(max-width: 767px){
              font-size: 13px;
            }
          }
        }
        .agreement-date-wrapper{
          margin-top: 13px;
          @media(max-width: 320px){
            flex-direction: column;
            row-gap: 10px;
          }
          .agreement-detail-date-label{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
            @media(max-width: 767px){
              font-size: 13px;
            }
          }
          .agreement-detail-date{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #162642;
            @media(max-width: 767px){
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}