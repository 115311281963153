.add-perk-wrapper{
  width: 100%;
  .ant-upload-list-text {
    // display: none;
  }
  .ant-upload.ant-upload-drag {
    background: #fff;
    border: 2px dashed #D4D4D4;
    box-sizing: border-box;
    border-radius: 27px;
    padding: 26px 50px;
    height: 100%;
    max-height: 370px;
  }
  @media (max-width: 768px){
    padding: 0 15px;
  }
  .header-left{
    margin-bottom: 25px;
    @media(max-width: 991px){
      margin: 25px 15px;
      padding: 0 30px;
    }
    @media(max-width: 375px){
      flex-direction: column;
      row-gap: 15px;
      align-items: flex-start !important;
    }
    .back-button{
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: #000000;
      span{
        margin-right: 5px;
      }
    }
    .feedbacks-templates{
      .feedbacks-templates-text{
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        margin-right: 10px;
        @media (max-width: 425px){
          font-size: 15px;
        }
      }
      .feedbacks-templates-sow{
        font-weight: 500;
        font-size: 17px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        border: 2px solid #E5C243;
        box-sizing: border-box;
        border-radius: 34px;
        padding: 5px 20px;
        margin-right: 10px;
        @media (max-width: 425px){
          font-size: 15px;
        }
      }
      .feedbacks-templates-msa{
        font-weight: 500;
        font-size: 17px;
        line-height: 27px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        border: 2px solid #E5C243;
        box-sizing: border-box;
        border-radius: 34px;
        padding: 5px 20px;
        @media (max-width: 425px){
          font-size: 15px;
        }
      }
    }
  }

  .perk-card-main{
    background: #FFFFFF;
    box-shadow: 0px 2px 19px rgba(32, 14, 50, 0.12);
    border-radius: 13px;
    margin-bottom: 50px;
    //padding: 50px 0 0;

    .perk-title-wrapper{
      background: #000000;
      border-radius: 13px 13px 0px 0px;
      padding: 25px 50px;
      @media( max-width: 767px){
        padding: 25px 25px;
      }
      .perk-title{
        font-weight: 500;
        font-size: 19px;
        line-height: 24px;
        text-transform: capitalize;
        color: #FFFFFF;
        margin-bottom: 0;
      }
      .perk-close-icon{
        color: #fff;
      }
    }
    .add-perk-card{
      padding: 50px 50px 0;
      @media(min-width: 992px) and (max-width: 1199px){
        padding: 50px 40px;
      }
      @media (min-width: 768px) and (max-width: 991px){
        padding: 40px 25px;
      }
      @media(max-width: 767px){
        padding: 20px 20px 0;
      }
      .perk-form{
        .perk-form-group{
          //margin-top: 30px;
          margin-bottom: 0;
          @media (max-width: 991px){
            margin-top: 20px;
          }
          @media (max-width: 767px){
            margin-top: 35px;
          }
          .perk-label{
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
          }
          .perk-input{
            background: #FFFFFF;
            border: 2px solid #D4D4D4;
            box-sizing: border-box;
            border-radius: 38px;
            height: 38px;
          }
          .perk-select{
            background: #FFFFFF;
            border: 2px solid #D4D4D4;
            box-sizing: border-box;
            border-radius: 38px;
            height: 38px;
          }
          .perk-textarea{
            background: #FFFFFF;
            border: 2px solid #D4D4D4;
            box-sizing: border-box;
            border-radius: 26px;
            height: 121px;
          }
          .perk-editor{
            .tox-tinymce{
              background: #FFFFFF;
              border: 2px solid #D4D4D4;
              box-sizing: border-box;
              border-radius: 24px;
              .tox-statusbar{
                display: none;
              }
            }
          }
          .perk-button-group{
            column-gap: 15px;
            @media(max-width: 375px) {
              flex-direction: column;
              row-gap: 15px;
            }
            .perk-type-button{
              border: 1.5px solid #E5C243;
              box-sizing: border-box;
              border-radius: 53px;
              font-weight: normal;
              font-size: 16px;
              line-height: 17px;
              text-align: center;
              text-transform: capitalize;
              color: #162642;
              padding: 11px 20px;
              background: #ffffff;
              @media (min-width: 768px) and (max-width: 991px){
                font-size: 13px;
                padding: 10px 15px;
              }
              @media (max-width: 767px) {
                font-size: 14px;
                padding: 10px 15px;
              }
            }
            .active{
              background: #E5C243;
              color: #000;
            }
          }
        }
      }
      .add-perk-img-wrapper{
        column-gap: 45px;
        @media(max-width: 1120px){
          flex-direction: column;
        }
        .profile-img{}
        .perk-img-upload{
          @media(max-width: 767px){
            width: 100%;
            margin-top: 25px;
          }
          .add-banner-text{
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 12px;
          }
          .add-perk-user-wrapper{
            
            label {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            //column-gap: 45px;
            @media (min-width: 768px) and (max-width: 991px){
              padding: 25px 30px;
              column-gap: 30px;
            }
            @media (max-width: 767px){
              flex-direction: column;
              padding: 20px 20px;
            }
            .perk-upload-box{
              .perk-upload-type{
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
                margin-bottom: 15px;
                margin-top: 15px;

              }
              .perk-upload-drag{
                font-weight: normal;
                font-size: 15px;
                line-height: 23px;
                color: #000000;
                margin-bottom: 5px;
                @media(max-width: 767px){
                  text-align: center;
                }
              }
              .perk-or-text{
                font-weight: normal;
                font-size: 15px;
                line-height: 23px;
                color: #000000;
                margin-bottom: 15px;
              }
              .perk-upload-buttons{
                @media(max-width: 767px){
                  display: flex;
                  flex-direction: column;
                  row-gap: 25px;
                }
                .upload-btn-choose{
                  background: #E5C243;
                  border-radius: 38px;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  color: #000000;
                  padding: 6px 20px;
                  border: none;
                }
                .upload-btn-gallery{
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  color: #000000;
                  padding: 6px 20px;
                  border: 2px solid rgba(229, 194, 67, 1);
                  background: #FFFFFF;
                  border-radius: 38px;
                  margin-left: 12px;
                  @media(max-width: 767px){
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    .addPerkCard {
      @media (max-width: 1120px) {
        flex-direction: column !important; 
      }
    }
    .add-perk-buttons{
      padding: 30px 50px;
      @media (max-width: 767px){
        padding: 40px 20px;
        flex-direction: column-reverse;
        row-gap: 19px;
      }
      .button-group{
        width: 100%;
        @media(max-width: 767px){
          flex-direction: row-reverse;
        }
        @media (max-width: 375px){
          flex-direction: column;
          row-gap: 19px;
        }
        .button-cancel{
          border: 2px solid #E5C243;
          box-sizing: border-box;
          border-radius: 53px;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          padding: 15px 30px;
          background: transparent;
          @media (min-width: 768px) and (max-width: 991px){
            padding: 10px 25px;
            font-size: 18px;
          }
          @media(max-width: 767px){
            padding: 10px 45px;
          }
          @media(max-width: 375px){
            width: 100%;
          }
        }
        .button-save{
          background: #E5C243;
          border-radius: 53px;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          padding: 16px 24px;
          border: none;
          @media (min-width: 768px) and (max-width: 991px){
            padding: 10px 20px;
            font-size: 18px;
          }
          @media (max-width: 767px){
            padding: 10px 30px;
          }
          @media(max-width: 375px){
            width: 100%;
          }
        }

      }
      .button-publish{
        background: #E5C243;
        border-radius: 53px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        padding: 16px 24px;
        border: none;
        margin-left: 20px;
        &:focus{
          box-shadow:unset !important;
          background: #E5C243;
          color: #000;
        }
        @media (min-width: 768px) and (max-width: 991px){
          padding: 10px 20px;
          font-size: 18px;
          margin-left: 20px;
        }
        @media(max-width: 767px){
          margin-left: 0;
        }
      }
    }
  }
}